import Flag from '../../assets/icons/flag.svg';
import GoldBanner from '../../assets/icons/goldbanner.svg';
// import Streak from '../../assets/icons/streak.svg';
import React, { useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import DropletIcon from '../../assets/icons/DropletIcon';
import UserUnitProgress from './components/UserUnitProgress';
import UserSubscription from './components/UserSubscription';
import organizeUnitElementData from '../../shared/util/organizeUnitElementData';
import AchievementsCard from '../../components/Achievements/AchievementsCard';
import Button from '../../components/ui/Button/Button';
import NavigationButton from '../../components/Navbar/components/NavigationButton';
import { Link } from 'react-router-dom';
import useAchievements from '../../shared/hooks/useAchievements';
import achievementIcons from '../../components/Achievements/achievementIcons.js';
import DoorIcon from '../../assets/icons/door.svg';

/**
 * The profile page.
 * @returns {React.JSX.Element} The profile page.
 */
export default function Profile() {
    const { logout, subscriptionStatus, fetchUserProfile, user } = useAuth();
    const { achievements, getUserAchievementProgress } = useAchievements();

    useEffect(() => {
        fetchUserProfile();
    }, []);

    const data = organizeUnitElementData(user?.progress);

    return (
        <div className="w-full max-w-xl">
            <div className="flex items-center justify-between border-b-2 pb-4">
                <h1 className="large-title flex-1 text-left">Your Profile</h1>
                <NavigationButton
                    onClick={logout}
                    className="gap-4 px-4 py-2 md:text-base"
                >
                    <img src={DoorIcon} className="h-8" />
                    Logout
                </NavigationButton>
            </div>

            <div className="mt-6 flex items-start justify-between">
                <div>
                    <h2 className="medium-title capitalize">
                        {user?.name.given[0]} {user?.name.family}
                    </h2>
                    <p className="text-xl text-gray-400">{user?.username}</p>
                    <div></div>
                </div>
                <div className="small-title flex size-36 items-center justify-center gap-4 rounded-full border-2 bg-green-400 text-gray-600 shadow-sm md:text-2xl">
                    {user?.name.given[0][0] + user?.name.family[0]}
                </div>
            </div>

            <div className="mt-10">
                <h2 className="medium-title">Statistics</h2>
                <div className="mt-10 grid grid-cols-2 justify-between gap-4">
                    <div className="flex w-full items-center gap-3 rounded-2xl border-2 bg-white px-4 py-4">
                        <DropletIcon className="size-8" />
                        <div className="">
                            <h3 className="small-title">{user?.points}</h3>
                            <p className="paragraph">Total points</p>
                        </div>
                    </div>
                    <div className="flex w-full items-center gap-3 rounded-2xl border-2 bg-white px-4 py-4">
                        <img src={Flag} className="h-10" alt="Total Points" />
                        <div className="">
                            <h3 className="small-title">
                                {user?.progress?.unitsCompleted.length}
                            </h3>
                            <p className="paragraph">Units Completed</p>
                        </div>
                    </div>
                    <div className="flex w-full items-center gap-3 rounded-2xl border-2 bg-white px-4 py-4">
                        <img
                            src={GoldBanner}
                            className="h-10"
                            alt="Total Points"
                        />
                        <div className="">
                            <h3 className="small-title">
                                {user?.progress?.elementsCompleted.length}
                            </h3>
                            <p className="paragraph">Elements Completed</p>
                        </div>
                    </div>
                    {/*
                    <div className="flex w-full items-center gap-3 rounded-2xl border-2 bg-white px-4 py-4">
                        <img
                            src={Streak}
                            className="size-8"
                            alt="Total Points"
                        />
                        <div className="">
                            <h3 className="small-title">{streak.longest}</h3>
                            <p className="paragraph">Longest Streak</p>
                        </div>
                    </div>
                    */}
                </div>

                <div className="mt-10">
                    <div className="flex w-full justify-between">
                        <h2 className="medium-title">Achievements</h2>
                        <Link to="/achievements">
                            <Button variant="link" id='allAchievements'>View All</Button>
                        </Link>
                    </div>

                    <div className="mt-10 flex gap-4">
                        {achievements.slice(0, 3).map((achievement) => {
                            const { progress, isUnlocked } =
                                getUserAchievementProgress(achievement._id);

                            const icon =
                                achievementIcons[achievement.achievementId] ||
                                achievementIcons.default;

                            return (
                                <AchievementsCard
                                    key={achievement._id}
                                    value={
                                        isUnlocked
                                            ? achievement.maxValue
                                            : progress
                                    }
                                    maxValue={achievement.maxValue}
                                    title={achievement.name}
                                    Image={icon}
                                    isUnlocked={isUnlocked}
                                />
                            );
                        })}
                    </div>
                </div>

                <div className="mt-10">
                    <h2 className="medium-title">Manage Your Information</h2>
                    <UserUnitProgress data={data} />
                </div>
                {subscriptionStatus === 'active' && (
                    <div className="mt-10">
                        <h2 className="medium-title">Manage Subscription</h2>
                        <UserSubscription />
                    </div>
                )}
            </div>
        </div>
    );
}
