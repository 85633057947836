/**
 * Generates a random greeting message based on the current time of day and an optional name.
 * @param {string} [name="user"] - The name to include in the greeting message. Defaults to "user" if not provided.
 * @returns {string} A random greeting message appropriate for the current time of day, personalized with the provided name.
 */
export default function getRandomGreeting(name = "user") {
    const currentHour = new Date().getHours();
  
    const messages = {
      morning: [
        `Good morning, ${name}! ☀️`,
        `Rise and shine, ${name}! 🌅`,
        `Morning, sunshine, ${name}! 🌞`,
        `Wakey wakey, ${name}! 🍳`,
        `Up and at 'em, ${name}! 🚀`,
        `Another day, another brew, ${name}! ☕`,
        `Time to seize the day, ${name}! 🕰️`,
        `The early bird gets the worm, ${name}! 🐦`
      ],
      afternoon: [
        `Good afternoon, ${name}! ☀️`,
        `Hope your day is going well, ${name}! 😎`,
        `Keep on shining, ${name}! ✨`,
        `You're halfway there, ${name}! 🏃`,
        `Afternoon delight, ${name}! 🎉`,
        `Stay sharp, ${name}! 🧠`,
        `Tea time, ${name}? 🍵`,
        `The day’s not over yet, ${name}! ⏰`
      ],
      evening: [
        `Good evening, ${name}! 🌙`,
        `Relax, it's evening time, ${name}! 🛋️`,
        `Evening breeze, please, ${name}! 🍃`,
        `Let the night unfold, ${name}! 🌌`,
        `Time to unwind, ${name}! 🛀`,
        `Moonlight magic, ${name}! 🌕`,
        `Cozy up, ${name}, it's evening! 🔥`,
        `Sundown, slowdown, ${name}! 🌅`
      ],
      night: [
        `Good night, ${name}! 🌙`,
        `Sleep tight, ${name}! 🛏️`,
        `Sweet dreams, ${name}! 😴`,
        `Nighty night, ${name}! 🌜`,
        `Catch some Z's, ${name}! 💤`,
        `Don’t let the bed bugs bite, ${name}! 🐜`,
        `Drift off to dreamland, ${name}! 🌠`,
        `Starry night, sleep tight, ${name}! ⭐`
      ]
    };
  
    let timeOfDay;
  
    if (currentHour >= 5 && currentHour < 12) {
      timeOfDay = 'morning';
    } else if (currentHour >= 12 && currentHour < 18) {
      timeOfDay = 'afternoon';
    } else if (currentHour >= 18 && currentHour < 22) {
      timeOfDay = 'evening';
    } else {
      timeOfDay = 'night';
    }
  
    const selectedMessages = messages[timeOfDay];
    const randomMessage = selectedMessages[Math.floor(Math.random() * selectedMessages.length)];
  
    return randomMessage;
  }