import React from 'react';
import { useAuth } from '../../../context/AuthContext';
import DropletIcon from '../../../assets/icons/DropletIcon';
// import Streak from '../../../assets/icons/streak.svg';


/**
 * Displays user stats including points and subscription status.
 * @returns {React.JSX.Element} The `UserStats` component.
 */
const UserStats = () => {
  const { points, subscriptionStatus } = useAuth();

  return (
    <div className="m-2 px-2 pt-0 md:mb-10 md:p-0 flex gap-4">
      <span className="font-bold">Your Stats:</span>
      <div className="flex items-center gap-1">
        <p className="font-bold">{points}</p>
        <DropletIcon />
      </div>
      {/* {streak.current > 0 && (
        <div className="flex items-center gap-1">
          <p className="font-bold">{streak.current}</p>
          <img
            src={Streak}
            className="h-4 w-4 mx-0.5 my-px"
            alt="Wave"
          />
        </div>
      )} */}
      {subscriptionStatus === 'active' && (
        <p className="font-extrabold tracking-wide text-sapphireBlue-600">
          Infinity
        </p>
      )}
    </div>
  );
};

export default UserStats;
