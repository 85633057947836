import { Link } from 'react-router-dom';
import Button from '../../components/ui/Button/Button';
import NotFoundMascot from '../../assets/mascots/404Mascot.svg';



/**
 * NotFound page to display a 404 error page with a navigation button back to Journey page.
 * 
 * @function NotFound
 * @returns {React.JSX.Element} The rendered NotFound component.
 */
function NotFound() {

    return (
        <div className="flex flex-col items-center justify-center h-[80vh] bg-white p-8">
            <div className="flex flex-col items-center text-center space-y-8 md:flex-row md:space-y-0 md:space-x-8 md:text-left">
                <img
                    src={NotFoundMascot}
                    alt="404 Mascot"
                    className="h-48 pointer-events-none"
                />
                <div>
                    <h1 className="text-3xl font-bold text-gray-800 mb-4">
                    Oh no! This page wasn&apos;t found...
                    </h1>
                    <p className="text-lg text-gray-600 mb-6">
                    I&apos;m here to help. Let&apos;s get you right back on track.
                    </p>
                    <Link to="/journey">
                        <Button variant="primary">
                            Head Back
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default NotFound;
