import { createContext, useContext, useState } from 'react';
import {
    fetchPointsAPI,
    fetchUserProfileAPI,
    loginAPI,
    logoutAPI,
    verifyOTPAPI,
    verifySessionAPI,
} from '../api/userAPI';

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [points, setPoints] = useState(-1);
    // const [streak, setStreak] = useState({ current: 0, longest: 0 });
    const [isAuthenticated, setIsAuthenticated] = useState(false); // user passes login
    const [pointsDiff, setPointsDiff] = useState(0);
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [successfullyLoggedIn, setSuccessfullyLoggedIn] = useState(false); // user passes 2fa authentication

    const verifyOTP = async (data) => {
        try {
            const responseData = await verifyOTPAPI(data);
            // setStreak(responseData.streak);
            localStorage.removeItem('email');
            setSuccessfullyLoggedIn(true);
            await fetchUserProfile(); // fetch user profile after login
        } catch (error) {
            setSuccessfullyLoggedIn(false);
            setIsAuthenticated(false);
            throw error;
        }
    };

    const verifySession = async () => {
        try {
            await verifySessionAPI();
            setIsAuthenticated(true);
            setSuccessfullyLoggedIn(true);
        } catch (error) {
            setIsAuthenticated(false);
            setSuccessfullyLoggedIn(false);
            setErrorMessage(error.message);
        }
    };

    const fetchPoints = async () => {
        try {
            const data = await fetchPointsAPI();

            setPoints((prevPoints) => {
                if (prevPoints < data.points && prevPoints > -1) {
                    const diff = data.points - prevPoints;
                    setPointsDiff(diff);
                }
                return data.points;
            });
        } catch (error) {
            setIsAuthenticated(false);
            setErrorMessage(error.message);
        }
    };

    const login = async (data) => {
        try {
            await loginAPI(data);
            setIsAuthenticated(true);
        } catch (error) {
            setIsAuthenticated(false);
            throw error;
        }
    };

    const logout = async () => {
        try {
            await logoutAPI();
            setUser(null);
            setIsAuthenticated(false);
        } catch (error) {
            setErrorMessage(error.message);
        }
    };

    const fetchUserProfile = async () => {
        try {
            const data = await fetchUserProfileAPI();
            setUser(data);
            setSubscriptionStatus(data.subscriptionStatus);
        } catch (error) {
            setErrorMessage(error.message);
        }
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                successfullyLoggedIn,
                user,
                verifySession,
                login,
                logout,
                fetchPoints,
                points,
                setPointsDiff,
                pointsDiff,
                subscriptionStatus,
                setSubscriptionStatus,
                fetchUserProfile,
                errorMessage,
                setErrorMessage,
                verifyOTP,
                // streak,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
