import React from 'react';
import ShopItem from './ShopItem';
import rewards from './RewardsList';

/**
 * Renders a list of rewards based on the selected category.
 * @param {object} props - The component props.
 * @param {string} props.category - The category of rewards to display.
 * @returns {React.JSX.Element} The rendered list of rewards of a selected category.
 */
const RewardsCategories = ({ category }) => {
    return (
        <>
            {rewards
                .filter((reward) => reward.category === category)
                .map((reward, index) => (
                    <ShopItem key={index} reward={reward} />
                ))}
        </>
    );
};

export default RewardsCategories;
