import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Button from '../ui/Button/Button';
import { Link } from 'react-router-dom';
import React from 'react';
import RaysWithSVG from './components/SVGwithRays.jsx';

/**
 * Component renders a modal with achievement details.
 * @param {object} props - The component props.
 * @param {boolean} props.isOpen - Indicates whether the modal is open or not.
 * @param {Function} props.setIsOpen - Function to set the modal's open state.
 * @param {string} props.title - The title of the achievement.
 * @param {string} props.description - The description of the achievement.
 * @param {string} props.image - The URL of the achievement image.
 * @returns {React.JSX.Element} The rendered component.
 */
export default function GamifiedAchievementModal({
    isOpen,
    setIsOpen,
    title,
    description,
    image,
}) {
    const radius = 80;
    const strokeWidth = 15;
    const circumference = 2 * Math.PI * radius;

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50 font-sans"
                onClose={closeModal}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl border-4 border-orange-400 bg-gradient-to-b from-orange-50  via-orange-100 to-orange-50 bg-center p-6 text-center align-middle text-white shadow-inner shadow-orange-400 transition-all">
                                <div className="relative flex flex-col items-center justify-center gap-2">
                                    <div className="w-full">
                                        <div className="flex items-center justify-center px-4 py-2">
                                            <Dialog.Title
                                                as="h3"
                                                className="relative z-10 w-full border-b-2 border-orange-400 pb-2 text-lg font-extrabold leading-6 md:text-xl"
                                            >
                                                <p className="small-title w-full pt-2 font-bold text-orange-400">
                                                    Achievement Unlocked
                                                </p>
                                                <div className="large-title font-black text-gray-600">
                                                    {title}
                                                </div>
                                            </Dialog.Title>
                                        </div>
                                    </div>

                                    <div className="-m-4">
                                        <span className="relative flex h-60 w-60">
                                            <Transition.Child
                                                enter="animate-pop-bounce duration-1000"
                                                enterFrom="opacity-0 scale-35"
                                                enterTo="opacity-100 scale-100"
                                                className="relative inline-flex w-full items-center justify-center"
                                            >
                                                <RaysWithSVG
                                                    strokeWidth={strokeWidth}
                                                    radius={radius}
                                                    circumference={
                                                        circumference
                                                    }
                                                    Image={image}
                                                />
                                            </Transition.Child>
                                        </span>
                                    </div>
                                    <div className="small-title relative z-10 mt-4 flex w-full flex-col items-center px-4 text-center text-gray-600">
                                        {description ? (
                                            <div>{description}</div>
                                        ) : (
                                            <div>
                                                You just got yourself a badge to
                                                show off your progress
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <Transition.Child
                                    enter={`transition-all ease-in-out duration-700 delay-[500ms]`}
                                    enterFrom="opacity-0 translate-y-6"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition-all ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="justify-space z-10 mt-6 flex w-full flex-col gap-4 md:flex-row">
                                        <Link
                                            to="/achievements"
                                            className="outline-offest-4 w-full focus:outline-none"
                                        >
                                            <Button
                                                variant="secondary"
                                                className="relative z-20 mr-2 w-full items-center rounded-xl border-2 border-[#d1d5db] bg-white text-gray-700 shadow-lg shadow-[0_5px_0_0_#d1d5db] hover:shadow-[0_9px_0_0_#d1d5db] hover:bg-gray-100"
                                                onClick={closeModal}
                                            >
                                                Achievements
                                            </Button>
                                        </Link>
                                        <Button
                                            variant="primary"
                                            className="box-shadow relative z-20 w-full items-center border-orange-700 bg-orange-600 text-white shadow-[0_5px_0_0_#c2410c]  hover:bg-orange-600 hover:shadow-[0_9px_0_0_#c2410c] md:ml-2"
                                            onClick={closeModal}
                                        >
                                            Awesome!
                                        </Button>
                                    </div>
                                </Transition.Child>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
