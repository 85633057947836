export const GamifiedSlider = ({
    min,
    max,
    label,
    required,
    id,
    value,
    onChange,
    error,
    errorText,
    reference,
}) => {
    return (
        <div>
            {label && (
                <div className=" flex items-start gap-2">
                    <label
                        htmlFor={id}
                        className="small-title"
                    >
                        {label}
                    </label>
                    {required ? (
                        <span className="rounded-full bg-red-100 bg-opacity-50 px-1 py-0.5 ml-2 font-medium error-text">
                            *required
                        </span>
                    ) : (
                        <span className=" rounded-full bg-green-100 bg-opacity-50 px-1 py-0.5 font-medium success-text">
                            optional
                        </span>
                    )}
                </div>
            )}
            <div className="flex w-full flex-col items-center py-6">
                <div className="mb-2 flex w-full max-w-md justify-between  text-lg font-extrabold">
                    <span>{min}</span>
                    <span>{value}</span>
                    <span>{max}</span>
                </div>
                <input
                    id={id}
                    type="range"
                    min={min}
                    max={max}
                    value={value}
                    onChange={onChange}
                    className="slider-thumb h-4 w-full max-w-md cursor-pointer appearance-none rounded-full bg-gradient-to-r from-blue-400 to-blue-600 shadow-sm outline-none"
                    ref={reference}
                />
                {error && (
                    <p className="card-title error-text">
                        {errorText}
                    </p>
                )}
            </div>
        </div>
    );
};
