import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

export const GamifiedStandardMultiSelect = ({
    label,
    required,
    id,
    options,
    className,
    error,
    errorText,
    value,
    onChange,
}) => {
    const [selectedOptions, setSelectedOptions] = useState(value || []);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        setSelectedOptions(value || []);
    }, [value]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const toggleOption = (optionValue) => {
        const newSelectedOptions = selectedOptions.includes(optionValue)
            ? selectedOptions.filter((val) => val !== optionValue)
            : [...selectedOptions, optionValue];
        setSelectedOptions(newSelectedOptions);
        onChange(newSelectedOptions);
    };

    const isSelected = (optionValue) => selectedOptions.includes(optionValue);

    return (
        <div className={`relative ${className}`} ref={dropdownRef}>
            {label && (
                <label
                    htmlFor={id}
                    className="mb-2 block small-title text-gray-700"
                >
                    {label}
                    {required ? (
                        <span className="rounded-full bg-red-100 bg-opacity-50 px-1 py-0.5 ml-2 font-medium error-text">
                            *required
                        </span>
                    ) : (
                        <span className=" rounded-full bg-green-100 bg-opacity-50 px-1 py-0.5 font-medium success-text">
                            optional
                        </span>
                    )}
                </label>
            )}
            <button
                type="button"
                onClick={toggleDropdown}
                className="flex w-full cursor-default appearance-none items-center justify-between bg-white text-left shadow rounded-xl border-2 px-4 py-3 md:px-6 md:py-4 text-lg md:text-xl text-gray-700 leading-tight focus:border-transparent focus:outline-none focus:ring-2 focus:ring-black my-2"
            >
                <span className="block truncate">
                    {selectedOptions.length > 0
                        ? options
                              .filter((option) =>
                                  selectedOptions.includes(option.value)
                              )
                              .map((option) => option.label)
                              .join(', ')
                        : 'Select options'}
                </span>
                <span className="pointer-events-none">
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </span>
            </button>
            {isOpen && (
                <div className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className="relative cursor-default select-none py-2 pl-10 pr-4 hover:bg-gray-100"
                            onClick={() => toggleOption(option.value)}
                        >
                            {isSelected(option.value) && (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </span>
                            )}
                            <span
                                className={`block truncate text-lg ${isSelected(option.value) ? 'font-medium' : 'font-normal'}`}
                            >
                                {option.label}
                            </span>
                        </div>
                    ))}
                </div>
            )}
            {error && <p className="mt-1 text-sm error-text">{errorText}</p>}
        </div>
    );
};
