import cn from "../utils/cn.jsx";

export const GamifiedMultiSelect = ({
    title,
    required,
    id,
    options,
    className,
    error,
    errorText,
    value, // Now provided by Controller
    onChange, // Provided by Controller
}) => {
    // Handler for checkbox changes
    const handleChange = (optionValue) => {
        if (value.includes(optionValue)) {
            onChange(value.filter((v) => v !== optionValue)); // Remove the value
        } else {
            onChange([...value, optionValue]); // Add the value
        }
    };

    return (
        <>
            <div className=" flex items-start gap-2">
                <label
                    htmlFor={id}
                    className="mb-2 block small-title"
                >
                    {title}
                </label>
                {required ? (
                    <span className="rounded-full bg-red-100 bg-opacity-50 px-1 py-0.5 ml-2 font-medium error-text">
                        *required
                    </span>
                ) : (
                    <span className=" rounded-full bg-green-100 bg-opacity-50 px-1 py-0.5 font-medium success-text">
                        optional
                    </span>
                )}
            </div>
            <div className="grid grid-cols-2 items-center gap-4 px-4 md:grid-cols-4 md:px-6">
                {options.map(({ value: optionValue, label }) => (
                    <div
                        key={optionValue}
                        className="flex items-center justify-center"
                    >
                        
                            <label
                            className={cn(
                                'flex h-24 w-full cursor-pointer items-center justify-center rounded-lg border-2 px-4 py-2 text-center font-extrabold tracking-wide transition-all hover:bg-pictonBlue-200 md:text-lg my-2',
                                value.includes(optionValue) ? 'bg-pictonBlue-300' : 'bg-white',
                                className
                            )}
                            >
                            <input
                                type="checkbox"
                                value={optionValue}
                                checked={value.includes(optionValue)}
                                onChange={() => handleChange(optionValue)}
                                className="hidden" // Hide the checkbox but keep it functional
                            />
                            {label}
                        </label>
                    </div>
                ))}
            </div>

            {error && (
                <p className="small-title error-text">{errorText}</p>
            )}
        </>
    );
};
