import { useState, useEffect } from 'react';

/**
 * A custom React hook to determine if the current viewport is small.
 * @returns {boolean} `true` if the viewport width is less than 768px (mobile), `false` otherwise.
 */
export default function useIsMobile() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
}
