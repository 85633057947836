import { React, useState, useEffect } from 'react';
import notifyMascot from '../../assets/mascots/NotifyMascot.png';
import ideaMascot from '../../assets/mascots/IdeaMascot.webp';
import errorMascot from '../../assets/mascots/ErrorMascot.webp';
import { funFacts } from './FunFacts';
import NotifyModal from './components/NotifyModal'

/**
 * Renders a mascot button on the bottom right corner of the screen with a message modal.
 * @returns {React.JSX.Element} The rendered MascotNotifier component.
 */
const MascotNotifier = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [showMascot, setShowMascot] = useState(false);
    const [randomFact, setRandomFact] = useState("");
    const [conflict, setConflict] = useState(false)

    const checkShowMascot = () => {
        const lastShown = localStorage.getItem('mascotLastShown');
        const now = new Date().getTime();
        const timePassed = 6 * 60 * 60 * 1000; // 6 hours in milliseconds

        if (!lastShown || now - parseInt(lastShown) > timePassed) {
            setShowMascot(true);
        } else {
            setShowMascot(false);
        }
    };

    const handleClick = () => {
        setRandomFact(funFacts[Math.floor(Math.random() * funFacts.length)]); // Set the selected random fact in state
        setModalIsOpen(true);
        setShowMascot(false); // Hide mascot when the modal is opened
        localStorage.setItem('mascotLastShown', new Date().getTime());
    };

    useEffect(() => {
        checkShowMascot(); // Initial check
        const intervalId = setInterval(checkShowMascot, 1000); // Check every second

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    return (
        <>
            <button
                className="fixed right-4 z-30 bottom-16 md:-bottom-4"
                onClick={handleClick}
            >
                {showMascot && (
                    <img
                        src={notifyMascot}
                        alt="Mascot"
                        className="h-20 animate-bounce hover:opacity-80 hover:scale-105 transition-all"
                    />
                )}
            </button>
            {modalIsOpen && (
                <NotifyModal
                title={conflict ? "Something's Missing...": "Fun Fact!"}
                isOpen={modalIsOpen}
                setIsOpen={setModalIsOpen}
                message={conflict ? " " : randomFact?.fact || ''}
                source={conflict ? " " : `Source: ${randomFact?.source}`}
                mascot={conflict ? errorMascot : ideaMascot}
                />
            )}
        </>
    );
};

export default MascotNotifier;
