import { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useAuth } from './AuthContext';

const SocketContext = createContext(null);
const SOCKET_SERVER_URL =
    process.env.REACT_APP_SOCKET_SERVER_URL || 'http://localhost:8000';

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const { successfullyLoggedIn, setSubscriptionStatus } = useAuth();

    useEffect(() => {
        let newSocket;

        if (successfullyLoggedIn) {
            newSocket = io(SOCKET_SERVER_URL, {
                withCredentials: true,
                transports: ['websocket'], // Only use WebSocket transport
                upgrade: false, // Disallow upgrades to other transports
            });

            newSocket.on('connect', () => {
                console.log('Connected to Socket.IO server');
            });

            newSocket.on('subscriptionUpdated', (data) => {
                setSubscriptionStatus(data.subscriptionStatus);
            });

            newSocket.on('connect_error', (error) => {
                console.error('Socket connection error:', error);
            });

            setSocket(newSocket);
        } else {
            if (socket) {
                console.log('Disconnecting socket');
                socket.disconnect();
                setSocket(null);
            }
        }

        return () => {
            if (newSocket) {
                newSocket.close();
            }
        };
    }, [successfullyLoggedIn]); // Only connect/disconnect if successfullyLoggedIn changes

    return (
        <SocketContext.Provider value={{ socket }}>
            {children}
        </SocketContext.Provider>
    );
};
