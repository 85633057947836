import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GamifiedModal from '../../../components/DialogModal/GamifiedModal';
import Button from '../../../components/ui/Button/Button';
import ReadingMascot from '../../../assets/mascots/ReadingLogsMascot.webp';

/**
 * Renders the LogModal component.
 * @param {object} props The component props.
 * @param {object[]} props.logs The logs.
 * @returns {React.JSX.Element} The rendered LogModal component.
 */
const LogModal = ({ logs }) => {
    const navigate = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const modalTitle = <h2 className="medium-title text-center">Logs</h2>;
    return (
        <>
            <button
                className="group relative flex w-full items-center overflow-hidden rounded-xl bg-gradient-to-r from-blue-600 to-indigo-800 px-6 py-6 text-left text-white transition-all duration-500 hover:scale-105 hover:bg-gradient-to-br hover:shadow-lg active:scale-100"
                onClick={() => setModalIsOpen(true)}
            >
                <div className="flex-1">
                    <h3 className="medium-title">Add New Log</h3>
                    <p className="subtitle mt-3 transition-all group-hover:line-clamp-none">
                        Track something new to unlock your data!
                    </p>
                </div>
                <img
                    src={ReadingMascot}
                    alt="Droplet Reading"
                    className="pointer-events-none h-20 object-cover pr-4"
                />
            </button>

            <GamifiedModal
                isOpen={modalIsOpen}
                setIsOpen={setModalIsOpen}
                title={modalTitle}
            >
                <div className="flex justify-center">
                    <div className="my-4 grid grid-cols-2 gap-6 md:grid-cols-3">
                        {logs.map((log) => (
                            <div
                                key={log.label}
                                className="flex items-center justify-center"
                            >
                                <Button
                                    key={log.label}
                                    variant="outline"
                                    onClick={() => navigate(log.path)}
                                    className="flex w-full transform flex-col items-center justify-center rounded-lg bg-white px-6 py-8 text-lg font-extrabold transition-transform hover:scale-110"
                                >
                                    <img
                                        src={log.image}
                                        alt={log.label}
                                        className="mb-4 h-24 w-24"
                                    />
                                    {log.label}
                                </Button>
                            </div>
                        ))}
                    </div>
                </div>
            </GamifiedModal>
        </>
    );
};

export default LogModal;
