import React from 'react';
import TransitionWrapper from './TransitionWrapper';

/**
 * Renders a medication log entry with transition effects.
 * @param {object} props - The component props.
 * @param {object} props.medicationLog - The medication log data.
 * @param {boolean} props.isHovered - A boolean flag to control the transition visibility.
 * @returns {React.JSX.Element} The rendered component.
 */
const RenderMedicationLog = ({ medicationLog, isHovered }) => (
    <>
        <p className="font-bold">
            {/* Check if medication and medication.text exist */}
            {medicationLog?.medication?.text ||
                medicationLog?.medication?.coding[0].display ||
                'Medication'}
        </p>

        {/* Check if dose exists */}
        {medicationLog?.dose.value && (
            <p>{`Dosage: ${medicationLog.dose.value} ${medicationLog.dose.unit}`}</p>
        )}

        <TransitionWrapper isHovered={isHovered}>
            <div className="mt-1">
                {/* Check if fillStatus exists */}
                {medicationLog?.fillStatus && (
                    <p>{`Fill Status: ${medicationLog.fillStatus}`}</p>
                )}

                {/* Check if indication exists and contains a text field */}
                {medicationLog?.indication?.[0]?.text && (
                    <p>{`Indications: ${medicationLog.indication[0].text}`}</p>
                )}

                {/* Check if adherence exists */}
                {medicationLog?.adherence && (
                    <p>{`Adherence: ${medicationLog.adherence}`}</p>
                )}

                {/* Check if status exists */}
                {medicationLog?.status && (
                    <p>{`Status: ${medicationLog.status}`}</p>
                )}

                {/* Check if route exists and contains a text field */}
                {medicationLog?.route?.text && (
                    <p>{`Route: ${medicationLog.route.text}`}</p>
                )}

                {/* Check if notes exist */}
                {medicationLog?.notes && (
                    <p>{`Notes: ${medicationLog.notes}`}</p>
                )}
            </div>
        </TransitionWrapper>
    </>
);

export default RenderMedicationLog;
