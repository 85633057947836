import DropletIcon from '../../../assets/icons/DropletIcon';
import Button from '../../../components/ui/Button/Button';
import { useAuth } from '../../../context/AuthContext';

/**
 * Renders a shop item.
 * @param {object} props The component props.
 * @param {object} props.reward The reward.
 * @returns {React.JSX.Element} The rendered ShopItem component.
 */
const ShopItem = ({ reward }) => {
    const { points } = useAuth();
    return (
        <div className="mt-4 flex items-center rounded-2xl border-2 bg-white px-4 py-4 gap-2">
            <span className="mr-3 text-2xl">
                {typeof reward.icon !== 'string' ?
                ( <img
                    src={reward.icon}
                    className=" h-7 text-gray-500 transition duration-75  group-hover:text-[#777777] "
                /> )
                : (
                    <span className="h-7 text-gray-500 transition duration-75 group-hover:text-[#777777]">
                      {reward.icon} 
                    </span>
                  )}
            </span>
            <div className="flex flex-col gap-2 md:flex-row md:items-center">
                <div>
                    <h2 className="small-title">{reward.title}</h2>
                    <p className="paragraph">{reward.description}</p>
                    <div className="flex gap-1"></div>
                </div>
                <Button
                    className="flex gap-1 whitespace-nowrap h-fit w-fit"
                    disabled={points < reward.price}
                >
                    <span className="text-nowrap text-base">Buy for:</span>
                    <span className="flex items-center gap-1 text-base">
                        <p className="whitespace-nowrap">{reward.price}</p>
                        <DropletIcon />
                    </span>
                </Button>
            </div>
        </div>
    );
};

export default ShopItem;
