import HealthCalendar from './components/HealthCalendar';
import React, { useState } from 'react';
import HealthLogs from './components/HealthLogs';
import useFetchHealthData from '../../shared/hooks/useFetchHealthData';
import LogModal from './components/LogModal';
import FeatureRequestModal from '../../components/DialogModal/FeatureRequestModal';

import CookingMascot from '../../assets/mascots/CookingMascot.svg';
import SleepingMascot from '../../assets/mascots/SleepingMascot.svg';
import RelaxedMascot from '../../assets/mascots/RelaxedMascot.svg';
import SurfingMascot from '../../assets/mascots/SurfingMascot.svg';
import SupplementsMascot from '../../assets/mascots/SupplementsMascot.webp';
import HikingMascot from '../../assets/mascots/HikingMascot.svg';
import PainMascot from '../../assets/mascots/PainMascot.svg';
import ListeningMascot from '../../assets/mascots/ListeningMascot.svg';
import MedicationMascot from '../../assets/mascots/MedicationMascot.svg';

const logs = [
    {
        label: 'Food',
        path: '/logs/food',
        image: CookingMascot,
    },
    {
        label: 'Sleep',
        path: '/logs/sleep',
        image: SleepingMascot,
    },
    {
        label: 'Mood',
        path: '/logs/mood',
        image: RelaxedMascot,
    },
    {
        label: 'Water',
        path: '/logs/water',
        image: SurfingMascot,
    },
    {
        label: 'Supplement',
        path: '/logs/supplement',
        image: SupplementsMascot,
    },
    {
        label: 'Exercise',
        path: '/logs/exercise',
        image: HikingMascot,
    },
    {
        label: 'Pain',
        path: '/logs/pain',
        image: PainMascot,
    },
    {
        label: 'Symptom',
        path: '/logs/symptom',
        image: ListeningMascot,
    },
    {
        label: 'Medication',
        path: '/logs/medication',
        image: MedicationMascot,
    },
];

/**
 * Renders the Health Page.
 * @returns {React.JSX.Element} The rendered Health Page.
 */
export default function HealthPage() {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const healthData = useFetchHealthData(selectedDate);
    return (
        <div className="h-full w-full max-w-xl">
            <div className="flex items-center border-b-2 pb-4">
                <h1 className="large-title ml-12 flex-1 text-center">Health</h1>
                <FeatureRequestModal type="Feature" />
            </div>
            <div className="mt-10 flex h-full flex-col gap-4">
                <HealthCalendar
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                />
                <div>
                    <div className="flex justify-between">
                        <h2 className="medium-title mb-4 mt-4">Health Data</h2>
                    </div>
                    <LogModal logs={logs} />
                </div>
                <div>
                    <div className="flex flex-col gap-4">
                        <HealthLogs healthData={healthData} />
                    </div>
                </div>
            </div>
        </div>
    );
}
