import { React, useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../ui/Button/Button';
import { useAuth } from '../../context/AuthContext';
import JumpingExcitedMascot from '../../assets/mascots/JumpingExcitedMascot.gif';
import getRandomGreeting from './utils/getRandomGreeting.js';


/**
 * Displays a welcome modal to the user after successful login.
 * 
 * @returns {React.JSX.Element} The welcome modal component.
 */
const WelcomeModal = () => {

    const { user, fetchUserProfile, subscriptionStatus } = useAuth();

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch user profile information and set loading to false when done
        fetchUserProfile().finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (!loading) {
            if (user && user.onboarded && subscriptionStatus !== "incomplete") { // If user is logged in and onboarded and subscribed (do not show on subscription page)
                // Show the modal if the user is logged in and the welcome flag hasn't been set
                if (!localStorage.getItem('welcomeModalSeen')) {
                    setModalIsOpen(true);
                }
            } else {
                // If the user is not logged in, it means they are logged out, so clear the flag
                localStorage.removeItem('welcomeModalSeen');
            }
        }
    }, [user, loading]);

    const closeModal = () => {
        setModalIsOpen(false);
        localStorage.setItem('welcomeModalSeen', 'true');
    };

    return (
        <>
            {modalIsOpen && (
                <Transition appear show={modalIsOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-50 font-sans"
                        onClose={closeModal}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl border-4 border-sapphireBlue-800 bg-gradient-to-b from-sapphireBlue-100 via-white to-sapphireBlue-100 bg-center p-6 text-center align-middle shadow-inner shadow-md text-white transition-all">
                                        <div className="relative flex flex-col items-center justify-center gap-2">
                                            <Dialog.Title
                                                as="h3"
                                                className="pb-2 medium-title text-gray-700 md:large-title"
                                            >
                                                {getRandomGreeting(user?.name.given[0])}
                                            </Dialog.Title>

                                            <div className="my-4 h-48">
                                                <Transition.Child
                                                    enter="animate-expand-bounce duration-1000"
                                                    enterFrom="opacity-0 scale-35"
                                                    enterTo="opacity-100 scale-100"
                                                    className="relative inline-flex w-full items-center justify-center"
                                                >
                                                    <img src={JumpingExcitedMascot} className="h-48"/>
                                                    <div className="absolute bottom-0 z-[-1] left-1/2 -translate-x-1/2 w-32 h-24 blur-sm rounded-full bg-gray-500 opacity-20"></div>
                                                </Transition.Child>
                                            </div>

                                            <p className="text-gray-600">
                                                I&apos;m so excited to see you, let&apos;s take a few minutes to invest in your health!
                                            </p>
                                        </div>

                                        <Transition.Child
                                            enter="transition-all ease-in-out duration-700 delay-[500ms]"
                                            enterFrom="opacity-0 translate-y-6"
                                            enterTo="opacity-100 translate-y-0"
                                            leave="transition-all ease-in-out duration-300"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <div className="mt-6 flex w-full">
                                                <Button
                                                    variant="primary"
                                                    onClick={closeModal}
                                                    className="w-full"
                                                >
                                                    I&apos;m ready!
                                                </Button>
                                            </div>
                                        </Transition.Child>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            )}
        </>
    );
};

export default WelcomeModal;
