import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { LoadSpinner } from '../LoadSpinner/LoadSpinner';

/**
 * CheckoutRedirect component is used to redirect authenticated users to '/journey' if they are subscribed.
 * @param {object} props - Props for the CheckoutRedirect component
 * @param {React.ReactNode} props.children - The children to render.
 * @returns {React.JSX.Element} The CheckoutRedirect component.
 */
function CheckoutRedirect({ children }) {
    const {
        isAuthenticated,
        subscriptionStatus,
        verifySession,
        fetchUserProfile,
        user,
    } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const init = async () => {
            if (!user) {
                await fetchUserProfile();
            } else {
                await verifySession();
            }
            setIsLoading(false);
        };
        init();
    }, [verifySession]);

    if (isLoading) {
        return <LoadSpinner />;
    }

    // Redirect subscribed users to '/journey' and keep non-subscribed users on the checkout page
    if (isAuthenticated && subscriptionStatus === 'active') {
        return <Navigate to="/journey" replace />;
    }

    return children; // Render children (the checkout page content) if not redirected
}

export default CheckoutRedirect;
