import { useForm } from 'react-hook-form';
import { LogoOnly } from '../../../components/Logo/Logo';
import { createStripeCheckoutAPI } from '../../../api/stripeAPI';

const ProductDisplay = () => {
    const {
        handleSubmit,
        formState: { isSubmitting },
    } = useForm();
    const onSubmit = async () => {
        try {
            const { url } = await createStripeCheckoutAPI();
            window.location.href = url; // Redirect to Stripe Checkout
        } catch (error) {
            console.log(error.message || 'Failed to create checkout session');
        }
    };

    return (
        <div>
            <h1 className="mb-10 medium-title tracking-wide md:mb-20 md:text-3xl">
                Checkout
            </h1>

            <section className="mx-auto max-w-4xl rounded-2xl border-2 bg-white p-6 shadow-md">
                <div className="flex flex-col items-center md:flex-row">
                    <LogoOnly />
                    <div className="description ml-0 text-center md:ml-12 md:text-left">
                        <h3 className="mt-4 text-xl font-bold text-gray-900 md:mt-0 md:text-2xl">
                            BlueFairy Infinity - Annual Subscription
                        </h3>
                        <h5 className="text-lg text-gray-700">
                            Your health is your wealth! Get early access to features for $316/year
                        </h5>
                        <p className="mt-2 text-gray-600">
                            Subscribe to the BlueFairy Infinity plan and be the
                            first to explore new features added to our platform.
                            Enjoy an uninterrupted, premium experience all year
                            round.
                        </p>
                    </div>
                </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="mt-8 text-center"
                >
                    <button
                        type="submit"
                        className={`mt-4 rounded-lg px-8 py-2 text-lg font-semibold shadow-md transition-shadow duration-300 ease-in-out hover:shadow-lg ${
                            isSubmitting
                                ? 'cursor-not-allowed bg-gray-300 text-gray-500'
                                : 'bg-blue-600 text-white hover:bg-blue-700'
                        }`}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Processing...' : 'Subscribe Now'}
                    </button>
                </form>
            </section>
        </div>
    );
};

export default ProductDisplay;
