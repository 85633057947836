// how forms should be laid out, expects subFields
// dynamic: is an array
// group: is more like object that groups fields together. Expects subFields. Groups containing 2 subFields will be side by side, anything more will be a column
const layoutOptions = {
    dynamic: 'dynamic',
    group: 'group',
    group_col: 'group-col',
}; //!dynamic means array, both expect subfields, input options dont

// all the input options that are currently supported for rendering on the client.
const inputOptions = {
    text: 'text',
    number: 'number',
    slider: 'slider',
    multiSelect: 'multiSelect',
    select: 'select',
    date: 'date',
    time: 'time',
    textarea: 'textarea',
    comboBox: 'comboBox',
    asyncMultiSelect: 'asyncMultiSelect',
    checkbox: 'checkbox',
};
const elementType = {
    form: 'form',
};

module.exports = {
    layoutOptions,
    inputOptions,
    elementType,
};
