export const rewards = [
    {
        title: 'Bottle: Hydration On-the-Go',
        description: 'Spring into action and stay refreshed with our durable and eco-friendly water bottles.',
        price: 35000, // Originally 1300
        icon: '🥤',
        category: 'Merch',
    },
    {
        title: 'T-shirt: Stay Fit, Stay Stylish',
        description: 'Branch out in style with our comfortable and trendy T-shirts.',
        price: 40000, // Originally 1500
        icon: '👕',
        category: 'Merch',
    },
    {
        title: 'Customized Breakfast Meal: Fuel Your Morning Right',
        description: 'Rise and shine with a delicious and nutritious breakfast tailored to your dietary needs and preferences.',
        price: 40000, // Originally 1500
        icon: '🍳',
        category: 'Recipes',
    },
    {
        title: 'Medical Supplies Discounts: Essential Savings on Healthcare',
        description: "Don't let healthcare costs sting! Save on medical supplies.",
        price: 40000, // Originally 1500
        icon: '🩹',
        category: 'Discounts and Vouchers',
    },
    {
        title: 'Gift Card $10: Share the Gift of Wellness',
        description: 'Plant the seeds of health and happiness with a $10 gift card.',
        price: 40000, // Originally 1500
        icon: '💵',
        category: 'Discounts and Vouchers',
    },
    {
        title: 'Customized Lunch Meal: Energize Your Midday',
        description: "Don't leaf your stomach growling! Enjoy a satisfying and healthy lunch.",
        price: 45000, // Originally 1700
        icon: '🥗',
        category: 'Recipes',
    },
    {
        title: 'Gift Card $15: A Thoughtful Wellness Gift',
        description: 'Let your loved ones blossom with a $15 gift card towards their wellness journey.',
        price: 45000, // Originally 1700
        icon: '💰',
        category: 'Discounts and Vouchers',
    },
    {
        title: 'Prescription Discounts: Affordable Medications Made Easy',
        description: 'No need to pine over high medication costs. Access discounts on prescriptions.',
        price: 48000, // Originally 1800
        icon: '💊',
        category: 'Discounts and Vouchers',
    },
    {
        title: 'Customized Dinner Meal: Wholesome & Delicious Evenings',
        description: 'Turnip the flavor and end your day with a nourishing dinner.',
        price: 50000, // Originally 2000
        icon: '🍽️',
        category: 'Recipes',
    },
    {
        title: 'Gift Card $20: The Perfect Wellness Present',
        description: 'Give the gift that keeps on growing with a $20 gift card to support their health goals.',
        price: 55000, // Originally 2200
        icon: '💳',
        category: 'Discounts and Vouchers',
    },
    {
        title: 'Sweatshirt: Cozy Comfort, Wellness Inspired',
        description: 'Wrap yourself in warmth and motivation with our soft and stylish sweatshirts.',
        price: 65000, // Originally 2500
        icon: '🧥',
        category: 'Merch',
    },
    {
        title: 'Dietitian: Expert Nutritional Guidance',
        description: 'Lettuce help you achieve your health goals with evidence-based dietary advice.',
        price: 65000, // Originally 2500
        icon: '🍲',
        category: 'Consultations/Coaching',
    },
    {
        title: 'Nutritionist: Personalized Nutrition Support',
        description: 'Grow into your best self with tailored nutrition recommendations.',
        price: 65000, // Originally 2500
        icon: '🥦',
        category: 'Consultations/Coaching',
    },
    {
        title: 'Health Coach: Achieve Your Wellness Goals',
        description: "Let's branch out and create a personalized plan for your wellness journey.",
        price: 65000, // Originally 2500
        icon: '💪',
        category: 'Consultations/Coaching',
    },
    {
        title: 'Life Coach: Unlock Your Full Potential',
        description: 'Bloom into the best version of yourself with the help of a life coach.',
        price: 65000, // Originally 2500
        icon: '🧘‍♀️',
        category: 'Consultations/Coaching',
    },
];

export default rewards; 