import PeekingMascot from '../../../assets/mascots/PeekingMascot.webp';
import ProviderMascot from '../../../assets/mascots/ProviderMascot.webp';
import WeightsMascot from '../../../assets/mascots/WeightsMascot.gif';
import CheeringMascot from '../../../assets/mascots/CheeringMascot.gif';
import BathingMascot from '../../../assets/mascots/BathingMascot.gif';
import ShoppingMascot from '../../../assets/mascots/ShoppingMascot.webp';
import ProfileSignMascot from '../../../assets/mascots/ProfileSignMascot.webp';
import OutsidePeekingMascot from '../../../assets/mascots/OutsidePeekingMascot.webp';

const JoyrideSteps = (isMobile) => [
  {
    target: 'body',
    placement: 'center',
    title: "Hey there my BlueFairy Friend (BFF)!",
    content: (
      <div className="flex text-center justify-center items-center gap-4">
        <div>
          <img 
            src={PeekingMascot}
            alt="Mascot" 
            className="animate-expand-bounce"
          />
          <div>
            I am BlueFairy! I am so excited you’re here. Let me show you around for a minute.
          </div>
        </div>
      </div>
    ),
    data: {
      next: isMobile ? "#mobileHEALTH" : "#HEALTH",
    },
    disableBeacon: true,
  },
  {
    target: 'body',
    placement: 'center',
    content: (
      <div className="flex-col text-center justify-center items-center gap-4">
        <div className="small-title pb-2">Journey Page</div>
          <div className="w-full flex justify-center p-8">
            <img 
              src={ProviderMascot}
              alt="Mascot" 
              className="h-28 animate-expand-bounce"
            />
          </div>
          <div>
            Start here - Go on an adventure through the elements, helping your 
            provider to know you better.
          </div>
        </div>
    ),
    data: {
      previous: 'body',
      next: "#dropletPoints",
    },
    disableBeacon: true,
  },
  {
    target: isMobile ? "#mobileDropletPoints" : "#dropletPoints",
    content: (
      <div className="flex text-left items-center gap-4">
        <div>
          <div className="small-title pb-2">Drops Counter</div>
          <div>
            Keep track of your drops 💧, the currency of the BlueFairy world, right here!
          </div>
        </div>
        <img 
          src={BathingMascot}
          alt="Mascot" 
          className="h-28 animate-expand-bounce"
        />
      </div>
    ),
    data: {
      previous: isMobile ? "#mobileHEALTH" : "#HEALTH",
      next: isMobile ? "#mobileQUEST" : "#QUEST",
    },
  },
  {
    target: isMobile ? "#mobileHEALTH" : "#HEALTH",
    content: (
      <div className="flex text-left items-center gap-4">
        <div>
          <div className="small-title pb-2">Health Page</div>
          <div>
            Add logs, such as your exercise, food, sleeping, and more here. Click the add button and fill away!
          </div>
        </div>
        <img 
          src={WeightsMascot}
          alt="Mascot" 
          className="h-24 animate-expand-bounce"
        />
      </div>
    ),
    data: {
      previous: "#dropletPoints",
      next: isMobile ? "#mobileSHOP" : "#SHOP",
    },
  },
  {
    target: isMobile ? "#mobileQUEST" : "#QUEST",
    content: (
      <div className="flex text-left items-center gap-4">
        <div>
          <div className="small-title pb-2">Quest Page</div>
          <div>
            Sign up for quests with one of our third-party provider partners!
          </div>
        </div>
        <img 
          src={CheeringMascot}
          alt="Mascot" 
          className="h-24 animate-expand-bounce"
        />
      </div>
    ),
    data: {
      previous: isMobile ? "#mobileHEALTH" : "#HEALTH",
      next: isMobile ? "#mobilePROFILE" : "#PROFILE",
    },
  },
  {
    target: isMobile ? "#mobileSHOP" : "#SHOP",
    content: (
      <div className="flex text-left items-center gap-4">
        <div>
          <div className="small-title pb-2">Shop Page</div>
          <div>
            Spend drops on the many rewards available. The more consistent you are - the faster you&apos;ll be able to purchase a reward!
          </div>
        </div>
        <img 
          src={ShoppingMascot}
          alt="Mascot" 
          className="h-24 animate-expand-bounce"
        />
      </div>
    ),
    data: {
      previous: isMobile ? "#mobileQUEST" : "#QUEST",
      next: isMobile ? "#mobilePROFILE" : "#PROFILE",
    },
  },
  {
    target: isMobile ? "#mobilePROFILE" : "#PROFILE",
    content: (
      <div className="flex text-left items-center gap-4">
        <div>
          <div className="small-title pb-2">Profile Page</div>
          <div>
            You can see an overview of your experience with me, edit your info, and manage your billing on this page.
          </div>
        </div>
        <img 
          src={ProfileSignMascot}
          alt="Mascot" 
          className="h-24 animate-expand-bounce"
        />
      </div>
    ),
    data: {
      previous: isMobile ? "#mobileSHOP" : "#SHOP",
      next: 'body',
    },
  },
  {
    target: 'body',
    placement: 'center',
    title: 'You got it now!',
    content: (
      <div className="flex text-center justify-center items-center gap-4">
        <div>
          <img 
            src={OutsidePeekingMascot}
            alt="Mascot" 
            className="animate-expand-bounce"
          />
          <div>
            Pretty simple, right? Thank you for letting me showing you around. You&apos;re awesome!
          </div>
        </div>
      </div>
    ),
    data: {
      previous: isMobile ? "#mobilePROFILE" : "#PROFILE",
    },
    disableBeacon: true,
  },
];

export default JoyrideSteps;
