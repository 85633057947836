import React from 'react';
import RenderPainLog from '../components/healthLogRenderers/RenderPainLog';
import RenderSymptomLog from '../components/healthLogRenderers/RenderSymptomLog';
import RenderExerciseLog from '../components/healthLogRenderers/RenderExerciseLog';
import RenderSupplementLog from '../components/healthLogRenderers/RenderSupplementLog';
import RenderMedicationLog from '../components/healthLogRenderers/RenderMedicationLog';
import RenderSleepLog from '../components/healthLogRenderers/RenderSleepLog';
import RenderMoodLog from '../components/healthLogRenderers/RenderMoodLog';
import RenderWaterLog from '../components/healthLogRenderers/RenderWaterLog';
import RenderFoodLog from '../components/healthLogRenderers/RenderFoodLog.jsx';

/**
 * Renders a log entry based on the log type.
 * @param {object} props - The component props.
 * @param {string} props.logType - The type of log to render.
 * @param {object} props.log - The log data.
 * @param {boolean} props.isHovered - A boolean flag to control the transition visibility.
 * @param {number} props.index - The index of the log entry.
 * @returns {React.JSX.Element} The rendered component.
 */
const RenderLog = ({ logType, log, isHovered, index }) => {
    switch (logType) {
        case 'painLog':
            return <RenderPainLog painLog={log} isHovered={isHovered} />;
        case 'symptomLog':
            return <RenderSymptomLog symptomLog={log} isHovered={isHovered} />;
        case 'exerciseLog':
            return (
                <RenderExerciseLog exerciseLog={log} isHovered={isHovered} />
            );
        case 'supplementLog':
            return (
                <RenderSupplementLog
                    supplementLog={log}
                    isHovered={isHovered}
                />
            );
        case 'medicationLog':
            return (
                <RenderMedicationLog
                    medicationLog={log}
                    isHovered={isHovered}
                />
            );
        case 'sleepLog':
            return <RenderSleepLog sleepLog={log} isHovered={isHovered} />;
        case 'moodLog':
            return <RenderMoodLog moodLog={log} isHovered={isHovered} />;
        case 'waterLog':
            return (
                <RenderWaterLog
                    waterLog={log}
                    isHovered={isHovered}
                    index={index}
                />
            );
        case 'foodLog':
            return <RenderFoodLog foodLog={log} isHovered={isHovered} />;
        // Add more logs as needed
        default:
            return null;
    }
};

export default RenderLog;
