import { useEffect, useState } from 'react';
import { fetchEarlyAccessUsers } from '../../api/adminAPI';

/**
 * Fetch early access users from the server
 * @param {string} searchQuery - Search query
 * @returns {object} earlyAccessUsers, page, totalPages, currentPage, totalUsers, loading, error, setPage, refetch
 */
export default function useFetchEarlyAccessUsers(searchQuery) {
    const [earlyAccessUsers, setEarlyAccessUsers] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalUsers, setTotalUsers] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const response = await fetchEarlyAccessUsers(page, searchQuery);
                setEarlyAccessUsers(response.users);
                setTotalPages(response.totalPages);
                setCurrentPage(response.currentPage);
                setTotalUsers(response.totalUsers);
                setError(null);
            } catch (err) {
                console.error('Error fetching early access users:', err);
                setError('Failed to fetch users. Please try again.');
            } finally {
                setLoading(false);
            }
        };
        !searchQuery && fetchUsers();
        const debounceTimer = setTimeout(() => {
            if (searchQuery) {
                setPage(1);
                fetchUsers();
            }
        }, 300);

        return () => clearTimeout(debounceTimer);
    }, [searchQuery]);

    return {
        earlyAccessUsers,
        setEarlyAccessUsers,
        page,
        totalPages,
        currentPage,
        totalUsers,
        loading,
        error,
        setPage,
        refetch: async () => {
            setPage(1);
            const fetchUsers = async () => {
                try {
                    setLoading(true);
                    const response = await fetchEarlyAccessUsers(
                        page,
                        searchQuery
                    );
                    setEarlyAccessUsers(response.users);
                    setTotalPages(response.totalPages);
                    setCurrentPage(response.currentPage);
                    setTotalUsers(response.totalUsers);
                    setError(null);
                } catch (error) {
                    console.error('Error fetching early access users:', error);
                    setError('Failed to fetch users. Please try again.');
                } finally {
                    setLoading(false);
                }
            };
            await fetchUsers();
        },
    };
}
