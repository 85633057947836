export const GamifiedSingleSelect = ({
    title,
    required,
    id,
    options,
    className,
    error,
    errorText,
    value, // Provided by Controller
    onChange, // Provided by Controller
}) => {
    return (
        <>
            <div className=" flex items-start gap-2">
                <label
                    htmlFor={id}
                    className="mb-2 block small-title"
                >
                    {title}
                </label>
                {required ? (
                    <span className="error-text rounded-full bg-red-100 bg-opacity-50 px-1 py-0.5 font-medium">
                        *required
                    </span>
                ) : (
                    <span className=" success-text rounded-full bg-green-100 bg-opacity-50 px-1 py-0.5 font-medium">
                        optional
                    </span>
                )}
            </div>
            <div className="grid grid-cols-2 items-center gap-4 px-4 md:grid-cols-4 md:px-6">
                {options.map(({ value: optionValue, label }) => (
                    <div
                        key={optionValue}
                        className="flex items-center justify-center"
                    >
                        <label
                            className={`flex h-24 w-full cursor-pointer items-center justify-center rounded-lg border-2 px-4 py-12 text-center font-extrabold tracking-wide transition-all hover:bg-pictonBlue-300 md:text-lg ${
                                value === optionValue
                                    ? 'bg-pictonBlue-400 text-black'
                                    : 'bg-white'
                            } ${className}`}
                        >
                            <input
                                type="radio"
                                value={optionValue}
                                onChange={() => onChange(optionValue)} // Update the selected value
                                checked={value === optionValue} // Mark as checked if it matches the current value
                                className="hidden" // Hide the radio input but keep it functional
                            />
                            {label}
                        </label>
                    </div>
                ))}
            </div>
            {error && <p className="small-title error-text">{errorText}</p>}
        </>
    );
};
