export const funFacts = [
  { fact: "Nurses play a central role as the main caregivers for patients in hospitals.", source: "barton.edu" },
  { fact: "Nurses represent the largest workforce segment within hospitals, according to the AACN.", source: "barton.edu" },
  { fact: "The origins of occupational therapy (OT) can be traced back to 100 BC.", source: "barton.edu" },
  { fact: "OT practices were first implemented by Asclepiades, a Greek physician, for treating mental illness.", source: "barton.edu" },
  { fact: "The color of your nails can provide insights into your health, indicating blood oxygen levels and circulation quality.", source: "barton.edu" },
  { fact: "The average age of a registered nurse (RN) is 52, suggesting a significant number may retire soon, leading to a high demand for nurses.", source: "barton.edu" },
  { fact: "BSNs are increasingly sought after, with many RN job postings specifying 'BSN preferred.'", source: "barton.edu" },
  { fact: "It's estimated that 80% of older adults have at least one chronic illness, and 68% have two or more.", source: "barton.edu" },
  { fact: "Physical therapy emerged during WWI when the army employed nurses as reconstruction aides to assist wounded soldiers with rehabilitation.", source: "barton.edu" },
  { fact: "The senior citizen population in the US is projected to reach 69 million by 2030 and 88.5 million by 2050, increasing the strain on the healthcare system.", source: "barton.edu" },
  { fact: "Occupational therapy officially began in 1917, with its founding members forming the first OT practice three years before women gained the right to vote.", source: "barton.edu" },
  { fact: "The lymphatic system and circulatory system function differently; while the circulatory system relies on the heart, the lymphatic system depends on physical activity to drain lymph nodes and boost immunity.", source: "barton.edu" },
  { fact: "Physical therapy was initially a female-only profession, with the first professional PT association in the US, led by Mary McMillan, founded in 1921.", source: "barton.edu" },
  { fact: "The idea of hand washing before surgery was initially ridiculed in the mid-1800s when Dr. Ignaz Semmelweis proposed it.", source: "barton.edu" },
  { fact: "The human body is a rapid cell-generating machine, producing approximately 3.8 million new cells every second!", source: "barton.edu" },
  { fact: "There is a global shortage of healthcare workers, with 57 countries facing a healthcare workforce crisis, requiring millions of positions to be filled.", source: "barton.edu" },
  { fact: "Healthcare is the fastest-growing industry in the US, with solid job security expected for the foreseeable future.", source: "barton.edu" },
  { fact: "Beard hair is the fastest-growing hair on the human body. If left untrimmed, an average man's beard could grow to nearly 30 feet long over his lifetime.", source: "hartfordhealthcare.org" },
  { fact: "Everyone has a unique scent, except for identical twins, who smell the same.", source: "hartfordhealthcare.org" },
  { fact: "A human fetus develops fingerprints by three months, making each person unique from the start.", source: "hartfordhealthcare.org" },
  { fact: "Like fingerprints, each person has a unique tongue print that can be used for identification.", source: "hartfordhealthcare.org" },
  { fact: "The middle finger's nail grows the fastest among all fingers.", source: "hartfordhealthcare.org" },
  { fact: "Studies suggest that people who frequently dream and have vivid dreams tend to have higher IQs.", source: "hartfordhealthcare.org" },
  { fact: "Sneezes can exceed 100 mph, while coughs typically reach around 60 mph.", source: "hartfordhealthcare.org" },
  { fact: "You can't tickle yourself because you know the exact time and method of the tickle, unlike when someone else does it.", source: "hartfordhealthcare.org" },
  { fact: "Your nose can remember around 50,000 different scents, making familiar smells like cookies evoke strong memories.", source: "hartfordhealthcare.org" },
  { fact: "Humans shed approximately 600,000 skin particles per hour, totaling about 1.5 pounds annually.", source: "hartfordhealthcare.org" },
  { fact: "Nearly half of all teenagers are sleep-deprived, even though they may seem energetic.", source: "hartfordhealthcare.org" },
  { fact: "We spend about 10% of our waking hours blinking, which happens 10-20 times per minute.", source: "hartfordhealthcare.org" },
  { fact: "Your heartbeat syncs with the music you listen to, slowing down or speeding up depending on the tempo.", source: "hartfordhealthcare.org" },
  { fact: "Athazagoraphobia is the fear of being forgotten or ignored.", source: "hartfordhealthcare.org" },
  { fact: "The word 'muscle' comes from a Latin term meaning 'little mouse,' as the Romans thought flexed biceps resembled a mouse.", source: "natgeokids.com" },
  { fact: "Human teeth are as strong as shark teeth.", source: "sciencemag.org" },
  { fact: "Nurses constitute the largest segment of hospital staff and are the primary caregivers for patients.", source: "aacnnursing.org" },
  { fact: "Job ads for registered nurses increasingly favor candidates with a BSN, making it an important credential for career advancement.", source: "aacnnursing.org" },
  { fact: "Occupational therapy was established in 1917 by a group of three men and three women, three years before women had the right to vote.", source: "napacenter.org" },
  { fact: "OT techniques have been around since 100 BC, when the Greek physician Asclepiades used them to treat mental illnesses.", source: "bchcares.org" },
  { fact: "Eleanor Roosevelt attended the 21st Annual Meeting of the American Occupational Therapy Association, honoring Eleanor Clarke Slagle, a key figure in OT history.", source: "lewisu.edu" },
  { fact: "Physical therapy began during WWI, with nurses, known as reconstruction aides, helping soldiers with rehabilitation through both physical and occupational therapy.", source: "orthocarolina.com" },
  { fact: "Physical therapy started as an all-female profession, with the first PT association in the US, led by Mary McMillan, established in 1921.", source: "apta.org" },
  { fact: "Research suggests that cold temperatures may help reduce allergies, inflammation, and improve mental clarity.", source: "rd.com" },
  { fact: "Optimism has been linked to lower risks of death from cancer, disease, infection, and stroke.", source: "nytimes.com" },
  { fact: "The American Speech-Language-Hearing Association (ASHA) was founded in 1925 at a meeting of the National Association of Teachers of Speech in New York City.", source: "bchcares.org" },
  { fact: "Singing is linked to the right hemisphere of the brain, while speaking is controlled by the left hemisphere, which may explain why some stroke survivors can still sing.", source: "discovermagazine.com" },
  { fact: "Some throat singers can produce up to four tones at once.", source: "discovermagazine.com" },
  { fact: "The body's smallest bones are located in the middle ear: the malleus, incus, and stapes.", source: "entandaudiology.com" },
  { fact: "Around 15 to 25 percent of Americans are 'supertasters,' meaning they have more taste buds than the average person.", source: "discovermagazine.com" },
  { fact: "Each person's tongue print is unique, just like fingerprints.", source: "hartfordhealthcare.org" },
  { fact: "It's impossible to tickle yourself because you can predict the sensation.", source: "hartfordhealthcare.org" },
  { fact: "Watching yourself in a mirror while on a treadmill can make the workout feel quicker and easier.", source: "health.com" },
  { fact: "Your belly button is home to nearly 2,400 different bacteria.", source: "webmd.com" },
  { fact: "Finger movements are controlled by muscles in the forearms, not in the fingers themselves.", source: "deserthandandpt.com" },
  { fact: "Nail color can indicate the oxygen levels and circulation in your blood.", source: "deserthandandpt.com" },
  { fact: "Depression and anxiety cost the global economy nearly $1 trillion annually in lost productivity.", source: "who.int" },
  { fact: "Nearly 1 in 5 American adults will experience a diagnosable mental health condition in any given year.", source: "mhanational.org" },
  { fact: "There’s no definitive physiological reason for sleep, and the amount needed varies from person to person.", source: "Touro SHS" }
];
