import { Disclosure, Transition } from '@headlessui/react';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import Element from './Element';
import CheeringMascot from '../../../assets/mascots/CheeringWithShadowMascot.webp'; // image #1
import DancingMascot from '../../../assets/mascots/DancingMascot.webp'; // image #2
import ReadingMascot from '../../../assets/mascots/ReadingMascotWithShadow.webp'; // image #3
import ExcitedMascot from '../../../assets/mascots/ExcitedMascotWithShadow.webp'; // image #4
import cn from '../../../components/ui/utils/cn';

// Define images arrays for each unit type
const unitImages = [
    [CheeringMascot, DancingMascot], // Images for unit 1
    [ReadingMascot], // Images for unit 2
    [ExcitedMascot], // Images for unit 3
];

// Define gradients for each index inner progress bar
const gradients = [
    'bg-gradient-to-r from-blue-600 to-blue-700', // sapphireBlue gradient
    'bg-gradient-to-r from-purple-600 to-purple-700', // purple gradient
    'bg-gradient-to-r from-green-600 to-green-700', // emerald gradient
];

// Define outer progress bar colors for each index outer progress bar
const outerBgColors = [
    'bg-sapphireBlue-500', // sapphireBlue
    'bg-purple-300', // purple
    'bg-emerald-300', // emerald
];

/**
 * The `Unit` component displays a button that shows the unit's title, description, and progress.
 * When the button is clicked, it expands to show its elements and mascots.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.unit - The unit data.
 * @param {string} props.unit.title - The title of the unit.
 * @param {string} props.unit.description - The description of the unit.
 * @param {Array<Object>} props.unit.elements - The elements within the unit.
 * @param {Object} props.progress - The user's progress data.
 * @param {Object} props.progress.elementsCompleted - The list of completed element IDs by the user.
 * @param {string} props.progress.currentUnit - The ID of the unit the user is currently on.
 * @param {number} props.index - The index of the unit in the list.
 * @param {number} props.progress - The progress percentage of the unit.
 *
 * @returns {React.JSX.Element} - The rendered unit component.
 */
export default function Unit({ unit, index, progress }) {
    const completedElements = unit.elements.filter((element) =>
        progress?.elementsCompleted?.includes(element._id)
    ).length;
    const totalElements = unit.elements.length;
    const percentage = (completedElements / totalElements) * 100;

    const buttonClass =
        index % 3 === 0
            ? 'bg-sapphireBlue-800 hover:bg-sapphireBlue-700 focus-visible:ring-sapphireBlue-500/75'
            : index % 3 === 1
              ? 'bg-purple-500 hover:bg-purple-400 focus-visible:ring-purple-500/75'
              : 'bg-emerald-500 hover:bg-emerald-400 focus-visible:ring-emerald-500/75';

    // Select the appropriate images array based on the unit index
    const images = unitImages[index % 3];

    return (
        <>
            <Disclosure
                defaultOpen={progress && progress.currentUnit == unit._id}
            >
                {({ open }) => (
                    <>
                        <Disclosure.Button
                            className={cn(
                                'my-10 flex w-full justify-between rounded-3xl px-5 py-8 text-left font-medium text-white focus:outline-none focus-visible:ring',
                                buttonClass
                            )}
                        >
                            <div>
                                <h2 className="medium-title">
                                    Unit #{index + 1}: {unit.title}
                                </h2>
                                <p className="subtitle mt-3 line-clamp-3">
                                    {unit.description}
                                </p>

                                <div className="flex">
                                    <div
                                        className={cn(
                                            'relative m-1 w-full overflow-hidden rounded-full transition-all duration-300 ease-in-out',
                                            outerBgColors[index % 3],
                                            open ? 'mt-4 h-6 p-1' : 'mt-4 h-1'
                                        )}
                                    >
                                        <div
                                            className={cn(
                                                'h-full rounded-full transition-all duration-300 ease-in-out',
                                                gradients[index % 3]
                                            )}
                                            style={{
                                                width: `${Math.round(percentage)}%`,
                                            }}
                                        ></div>
                                    </div>
                                    <span
                                        className={cn(
                                            'delay-50 flex gap-2 font-bold transition-all duration-300 ease-in-out',
                                            open
                                                ? 'ml-2 mt-4 h-full w-1 scale-100'
                                                : 'h-0 w-0 scale-0 opacity-0'
                                        )}
                                    >
                                        {Math.round(percentage)}%
                                    </span>
                                </div>
                            </div>
                            <div className="p-2">
                                <ChevronLeftIcon
                                    className={cn(
                                        'delay-50 h-8 w-8 stroke-white text-white transition-all duration-300 ease-in-out',
                                        open && '-rotate-90 transform'
                                    )}
                                />
                            </div>
                        </Disclosure.Button>

                        <Transition
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <Disclosure.Panel className="px-4 pb-2 pt-4">
                                <div className="flex w-full max-w-xl flex-col items-center justify-center gap-10">
                                    {unit.elements.map((element, idx) => (
                                        <div
                                            key={element._id}
                                            className="relative flex items-center"
                                        >
                                            {(idx + 1) % 3 === 0 && (
                                                <img
                                                    src={
                                                        images[
                                                            idx % images.length
                                                        ]
                                                    }
                                                    alt="Decoration"
                                                    className={cn(
                                                        'absolute m-5 w-24',
                                                        idx % 4 === 2
                                                            ? 'left-[-80px] md:left-[-160px]'
                                                            : 'right-[-80px] md:right-[-160px]'
                                                    )}
                                                />
                                            )}
                                            <Element
                                                element={element}
                                                index={idx}
                                                unitId={unit._id}
                                                progress={progress}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </Disclosure.Panel>
                        </Transition>
                    </>
                )}
            </Disclosure>
        </>
    );
}
