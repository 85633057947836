import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { Loading } from '../Loading/Loading';

/**
 * PrivateRoute component is used to protect routes that require authentication.
 * @param {object} props - Props for the PrivateRoute component
 * @param {React.ReactNode} props.children - The children to render.
 * @returns {React.JSX.Element} The PrivateRoute component.
 */
function PrivateRoute({ children }) {
    const {
        isAuthenticated,
        subscriptionStatus,
        verifySession,
        user,
        fetchUserProfile,
    } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        if (!isLoading) {
            const delay = setTimeout(() => {
                setShowLoader(false);
            }, 2200); // 2.2 seconds delay

            return () => clearTimeout(delay);
        }
    }, [isLoading]);

    useEffect(() => {
        const checkAccess = async () => {
            if (!user) {
                await fetchUserProfile();
            } else {
                await verifySession();
            }

            setIsLoading(false);
        };
        checkAccess();
    }, [verifySession]);

    if (isLoading || showLoader) {
        return <Loading message />;
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    if (subscriptionStatus !== 'active') {
        return <Navigate to="/checkout" replace />;
    }

    return children;
}

export default PrivateRoute;
