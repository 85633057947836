import { useContext, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import Button from '../../components/ui/Button/Button';
import { StandardInput } from '../../components/ui/Inputs/StandardInput';
import SloganMascot from "../../assets/mascots/SloganPlantMascot.gif";

const schema = yup
    .object({
        email: yup.string().email().required('Please enter a valid email'),
        password: yup.string().min(5).required('This field is required'),
    })
    .required();

/**
 * Renders the Login page.
 * @returns {React.JSX.Element} The rendered Login page.
 */
export default function Login() {
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);
    const [submitError, setSubmitError] = useState('');
    const {
        register,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = async (data) => {
        try {
            const { email, password } = data;
            await login({ email: email.toLowerCase(), password });
            localStorage.setItem('email', email.toLowerCase());
            navigate('/verify-otp');
        } catch (error) {
            setSubmitError(error.message || 'Something went wrong. Try again.');
        }
    };

    return (
        <div className="flex h-screen flex-col items-center justify-center gap-4 -mt-12">
            <div className="w-full max-w-2xl px-12 md:px-4 md:mt-8">
                <h1 className="text-3xl font-bold md:text-4xl ">Login</h1>
                <p className="mt-2 text-lg text-gray-600">
                    Enter your credentials below to continue investing in your health!
                </p>
            </div>

            <div className="w-full max-w-2xl px-4">
                <form
                    className="mb-4 rounded-lg bg-white px-8 py-4 text-black"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="mb-4">
                        <StandardInput
                            label="Email"
                            id="email"
                            type="email"
                            placeholder="Email"
                            error={errors.email}
                            errorText={
                                errors.email?.message ||
                                'Please enter a valid email'
                            }
                            register={register}
                        />
                    </div>
                    <div className="relative mb-4">
                        <StandardInput
                            label="Password"
                            id="password"
                            type="password"
                            placeholder="******************"
                            error={errors.password}
                            errorText={
                                errors.password?.message ||
                                'Please enter a valid password'
                            }
                            register={register}
                        />
                        <div className="text-end">
                            <Button variant="link" className="font-bold">
                                <Link to="/forgot-password" className="text-sm">
                                    Forgot Password
                                </Link>
                            </Button>
                        </div>
                    </div>
                    {submitError && (
                        <div className="error-text mb-4 flex items-center justify-center gap-2 rounded-lg bg-red-100 px-4 py-2 font-medium">
                            <ExclamationCircleIcon className="h-5 w-5" />
                            <p>{submitError}</p>
                        </div>
                    )}
                    <Button
                        className="mx-auto mt-4 w-full"
                        type="submit"
                        disabled={!isValid}
                        isLoading={isSubmitting}
                    >
                        Login
                    </Button>
                </form>
                <div className='w-full flex justify-center mt-4'>
                    <div className="relative">
                        <img src={SloganMascot} className="w-48 self-center"></img>
                        <div className="absolute -bottom-1 z-[-1] left-1/2 transform -translate-x-1/2 w-full h-6 bg-gray-300 rounded-full shadow-lg"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
