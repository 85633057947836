import React, { useState, useEffect } from 'react';
import RunningMascot from "../../assets/mascots/RunningMascot.gif";


const messages = [
    "Keep earning points so you can redeem them for some cool rewards!",
    "Consistency is key to a healthier you!",
    "Stay active and keep those points coming!",
    "Every step counts towards your health goals!",
    "Earn points today, feel better tomorrow!",
    "Your health journey is worth every point!",
    "Stay motivated and keep collecting those points!",
    "Healthier habits lead to a happier life!",
    "You're doing great, keep those points rolling in!",
    "Small steps make a big difference in your health!"
];

const getRandomMessage = () => messages[Math.floor(Math.random() * messages.length)];

function LoadingText() {
    const [index, setIndex] = useState(3);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIndex((prevIndex) => (prevIndex % 3) + 1);
        }, 250);

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);

    return (
        <span className="inline-flex items-center">
            <span className="large-title">Loading
                <span className={`transition-opacity ${index >= 1 ? 'opacity-100' : 'opacity-0'}`}>.</span>
                <span className={`transition-opacity ${index >= 2 ? 'opacity-100' : 'opacity-0'}`}>.</span>
                <span className={`transition-opacity ${index >= 3 ? 'opacity-100' : 'opacity-0'}`}>.</span>
            </span>
        </span>
    );
}

export const Loading = ({ message }) => {

    return (
        <div className="absolute inset-0 flex items-center bg-sky-100 justify-center">
            {
                <div className="text-center">
                    <img src={RunningMascot} className='w-36 my-4 mx-auto' alt="Running Droplet"/>
                    <LoadingText />
                    <p className="small-title tracking-wide text-gray-500"> {message ? getRandomMessage() : "Almost there!" }</p>
                </div>
            }
    </div>
    );    
};
