import cn from "../utils/cn.jsx";

export const GamifiedTimePicker = ({
    id,
    label,
    required,
    error,
    errorText,
    value,
    onChange,
    reference,
}) => {
    return (
        <div
            className={`flex flex-col ${error ? ' error-text' : 'text-gray-700'}`}
        >
            {label && (
                <div className=" flex items-start gap-2">
                    <label
                        htmlFor={id}
                        className="small-title"
                    >
                        {label}
                    </label>
                    {required ? (
                        <span className="rounded-full bg-red-100 bg-opacity-50 px-1 py-0.5 ml-2 font-medium error-text">
                            *required
                        </span>
                    ) : (
                        <span className=" rounded-full bg-green-100 bg-opacity-50 px-1 py-0.5 font-medium success-text">
                            optional
                        </span>
                    )}
                </div>
            )}
            <input
                type="time"
                id={id}
                value={value}
                ref={reference}
                onChange={(e) => onChange(e.target.value)}
                className={cn(
                    'w-full appearance-none rounded-xl border-2 px-4 py-3 my-2 text-lg leading-tight text-gray-700 shadow md:px-6 md:py-4 md:text-xl',
                    error ? 'border-red-500' : 'border-gray-300'
                  )}
            />
            {error && <p className="mt-1 text-sm">{errorText}</p>}
        </div>
    );
};
