export const feedbackMessages = {
  positiveReinforcement: [
    "Leaf it to you to make waves of progress! You're sprouting success!",
    "You’ve got kale-ifornia levels of dedication! Keep breezing through!",
    "Lettuce celebrate your growth - you're romaine-ing strong!",
    "You’re kale-ing it! Every ripple counts in your wellness journey.",
    "Berry nice! You’re sowing seeds and riding the waves of health.",
    "You're un-beet-able! Keep the wind in your sails and the veggies on your plate!",
    "Way to plant the seeds of success! Your health garden is blooming.",
    "You’re mint to be healthy! Keep making waves!",
    "You're radishing in your wellness journey - turnip the good vibes!",
    "You're glowing like a sunrise and flourishing like a well-tended garden.",
    "Fantastic! You're riding the tide of good health like a pro!"
  ],
  corrections: [
    "Lettuce tweak this a bit! You're almost there - just a small ripple away!",
    "You're on the right path - just a pea-sized adjustment and you’re golden!",
    "Just a small turnip, and you'll be ripe for success and sailing smoothly!",
    "Oops-a-daisy! Let's prune this info for a perfect fit!",
    "No big dill - just a little breeze to steer you back on course!"
  ],
  completion: [
    "You did it! Lettuce toast to your success - you're cruising ahead!",
    "All set! You're the cream of the crop and riding the wave of wellness.",
    "Success! Your efforts are bearing fruit and making waves!",
    "Finished! You've planted the seeds of a strong health journey - keep it growing!",
    "Victory! You’ve weathered the storm and are in full bloom!",
    "Milestone reached! Keep climbing and sailing - you’re sprouting new heights!",
    "Egg-cellent progress! You’re on your way to a fruitful and breezy future!"
  ],
  generalEncouragement: [
    "Keep the wind in your sails and the soil fertile - you're doing grape!",
    "You're making a splash and your efforts today will bear fruit tomorrow.",
    "Stay strong and keep growing - you're weathering the storm like a champ!",
    "Be-leaf in yourself! Every ripple and seed brings you closer to your wellness goals.",
    "You're a force of nature! Together, we’re building a flourishing garden of success."
  ],
};
