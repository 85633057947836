import React from 'react';
import { CheckIcon, LockClosedIcon, StarIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import Tooltip from '../../../components/ui/Tooltip/Tooltip';
import { IconStarFilled } from '@tabler/icons-react';

const ElementTooltip = ({ title, points }) => (
    <div>
        <label className="font-bold tracking-wide md:text-lg">{title}</label>
        <div className="flex items-center gap-1 font-bold">
            <span>Points: {points}</span>
            <IconStarFilled className="text-white" size={16} />
        </div>
    </div>
);

/**
 * @param {object} props - The props passed to the component
 * @param {object} props.element - The element object to render
 * @param {number} props.index - The index of the element in the list
 * @param {string} props.unitId - The id of the unit the element belongs to
 * @param {object} props.progress - The progress object of the user
 * @returns {React.JSX.Element} The JSX element to render
 */
export default function Element({ element, index, unitId, progress }) {
    const navigate = useNavigate();
    const { _id, title, points, elementId } = element;
    return (
        <>
            <div
                className={`${
                    index % 2 === 0 ? 'ml-20 self-center' : 'mr-20 self-center'
                }`}
            >
                <Tooltip
                    content={<ElementTooltip title={title} points={points} />}
                    position={`${index % 2 === 0 ? 'left' : 'right'}`}
                >
                    <button
                        onClick={() =>
                            navigate(`/unit/${unitId}/element/${elementId}`)
                        }
                        disabled={
                            _id !== progress.currentElement ||
                            progress?.elementsCompleted?.includes(_id)
                        }
                        className={`pushable ${
                            progress?.elementsCompleted?.includes(_id)
                                ? 'button-status-complete shadow-green-500'
                                : _id === progress.currentElement
                                  ? 'button-status-current shadow-blue-500'
                                  : 'button-status-locked shadow-gray-500'
                        } `}
                    >
                        <span className="pushable-shadow"></span>
                        <span className="edge"></span>
                        <span className="front flex h-12 items-center justify-center text-lg font-extrabold">
                            {progress?.elementsCompleted?.includes(_id) ? (
                                <CheckIcon className="-mt-4 h-8 stroke-white stroke-2" />
                            ) : _id === progress.currentElement ? (
                                <StarIcon className="-mt-4 h-8 stroke-white" />
                            ) : (
                                <LockClosedIcon className="-mt-4 h-8 stroke-white" />
                            )}
                        </span>
                    </button>
                </Tooltip>
            </div>
        </>
    );
}
