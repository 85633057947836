import cn from "../utils/cn.jsx";

export const GamifiedStandardInput = ({
    label,
    required,
    id,
    type,
    placeholder,
    error,
    errorText,
    className,
    onChange, // Provided by Controller
    onBlur, // Provided by Controller
    value,
    reference,
    customOnChange, // Custom prop for formatting onChange
    customOnBlur, // Custom prop for sanitizing onBlur
}) => {
    // Custom onChange handler that integrates custom formatting logic
    const handleChange = (e) => {
        if (customOnChange && typeof customOnChange === 'function') {
            // Directly pass the event object to the customOnChange function
            customOnChange(e);
        }
        onChange(e.target.value); // Use Controller's onChange with unformatted value
    };

    // Custom onBlur handler that integrates sanitizing logic
    const handleBlur = (e) => {
        let sanitizedValue = e.target.value;
        if (customOnBlur && typeof customOnBlur === 'function') {
            sanitizedValue = customOnBlur(sanitizedValue);
        }
        onBlur(sanitizedValue); // Use Controller's onBlur with sanitized value
    };

    const inputClassNames = cn(
        'flex w-full cursor-default items-center justify-between bg-white text-left shadow rounded-xl border-2 px-4 py-3 md:px-6 md:py-4 text-lg md:text-xl text-gray-700 leading-tight focus:border-transparent focus:outline-none focus:ring-2 focus:ring-black my-2',
        error ? 'border-red-500' : '',
        className
      );

    return (
        <>
            {label && (
                <div className="flex items-start gap-2">
                    <label
                        htmlFor={id}
                        className="small-title"
                    >
                        {label}
                    </label>
                    {required ? (
                        <span className="error-text rounded-full bg-red-100 bg-opacity-50 px-1 py-0.5 font-medium">
                            *required
                        </span>
                    ) : (
                        <span className=" success-text rounded-full bg-green-100 bg-opacity-50 px-1 py-0.5 font-medium">
                            optional
                        </span>
                    )}
                </div>
            )}
            <input
                id={id}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={handleChange} // Use the custom handleChange
                onBlur={handleBlur} // Use the custom handleBlur
                className={inputClassNames}
                ref={reference}
            />
            {error && <p className="small-title error-text">{errorText}</p>}
        </>
    );
};
