import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon, MinusCircleIcon } from '@heroicons/react/20/solid';
import { useFieldArray, useFormContext } from 'react-hook-form';
import GamifiedField from './GamifiedField';
import { useEffect } from 'react';

// Custom hook to handle field watching and error clearing
const useFieldWatcher = (fieldIndex) => {
    const {
        watch,
        clearErrors,
        formState: { errors },
    } = useFormContext();
    const watchField = watch(fieldIndex);
    useEffect(() => {
        clearErrors(fieldIndex);
    }, [watchField, clearErrors, errors, JSON.stringify(watchField)]);
};

const RenderFieldsArray = ({
    field,
    fieldIndex,
    fieldError,
    index,
    remove,
}) => {
    useFieldWatcher(fieldIndex);
    return (
        <Disclosure defaultOpen={true}>
            {({ open }) => (
                <>
                    <Disclosure.Button
                        as="div"
                        className="mb-2 flex w-full justify-between rounded-xl bg-purple-100 px-4 py-4 text-left font-medium hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                    >
                        <div className="flex items-center gap-2">
                            <button
                                className="error-text rounded-lg text-lg font-extrabold transition-all hover:text-red-300"
                                type="button"
                                onClick={() => remove(index)}
                            >
                                <MinusCircleIcon className="h-6" />
                            </button>
                            <span className="block font-bold text-eel md:text-lg">
                                {field.label + ' #' + (index + 1)}
                            </span>
                        </div>
                        <ChevronUpIcon
                            className={`${
                                open ? 'rotate-180 transform' : ''
                            } h-7 w-7 text-purple-500`}
                        />
                    </Disclosure.Button>
                    <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <Disclosure.Panel className="px-6 pb-2 pt-4 text-sm text-gray-500 md:px-12">
                            {field.subFields.map((subField, idx) => (
                                <div
                                    className="mt-4 w-full"
                                    key={`${fieldIndex}${subField.name}[${idx}]`}
                                >
                                    <GamifiedField
                                        field={subField}
                                        fieldIndex={`${fieldIndex}`}
                                    />
                                </div>
                            ))}
                            <p className="small-title error-text mt-1">
                                {' '}
                                {fieldError && fieldError.message}
                            </p>
                        </Disclosure.Panel>
                    </Transition>
                </>
            )}
        </Disclosure>
    );
};

export default function GamifiedFieldsArray({ field, fieldIndex, fieldError }) {
    const { fields, append, remove } = useFieldArray({
        name: fieldIndex,
    });
    const {
        watch,
        clearErrors,
        formState: { errors },
    } = useFormContext();
    const dynamicFieldValue = watch(fieldIndex);
    useEffect(() => {
        // Clear the error for the dynamic field if its value changes and it was previously in error
        if (dynamicFieldValue && errors[fieldIndex]) {
            clearErrors(fieldIndex);
        }
    }, [dynamicFieldValue, clearErrors]);
    return (
        <>
            <div className="flex gap-1">
                <h1 className="mb-2 block medium-title">
                    {field.label}
                </h1>
                {field.required ? (
                    <span className="error-text h-fit rounded-full bg-red-100 bg-opacity-50 px-1 py-0.5 font-medium">
                        *required
                    </span>
                ) : (
                    <span className=" success-text h-fit rounded-full bg-green-100 bg-opacity-50 px-1 py-0.5 font-medium">
                        optional
                    </span>
                )}
            </div>
            {field.description && (
                <p className="mb-4 font-bold text-gray-500">
                    {field.description}
                </p>
            )}

            {fields.map((item, index) => (
                <RenderFieldsArray
                    key={item.id}
                    field={field}
                    fieldIndex={`${fieldIndex}[${index}]`}
                    fieldError={fieldError && fieldError[index]}
                    index={index}
                    remove={remove}
                />
            ))}
            <button
                className="ml-12 mt-4 rounded-lg border bg-purple-200 px-4 py-2 text-lg font-extrabold text-eel transition-all hover:bg-purple-300"
                type="button"
                onClick={() => {
                    append({});
                    clearErrors(fieldIndex);
                }}
            >
                Add {field.title || field.label}
            </button>
            {fieldError && (
                <p className="small-title error-text mt-1">
                    {fieldError.message}
                </p>
            )}
        </>
    );
}
