import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useEffect, useRef } from 'react';
import { useState } from 'react';

export const GamifiedStandardSelect = ({
    label,
    required,
    id,
    options,
    className,
    error,
    errorText,
    value, // Now comes from Controller
    onChange, // Now comes from Controller, adjust to handle the option value correctly
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const toggleOption = (optionValue) => {
        onChange(optionValue);
    };

    const isSelected = (optionValue) => options.includes(optionValue);
    return (
        <div className={`relative ${className}`} ref={dropdownRef}>
            {label && (
                <div className=" flex items-start gap-2">
                    <label htmlFor={id} className="small-title">
                        {label}
                    </label>
                    {required ? (
                        <span className="error-text ml-2 rounded-full bg-red-100 bg-opacity-50 px-1 py-0.5 font-medium">
                            *required
                        </span>
                    ) : (
                        <span className="success-text rounded-full bg-green-100 bg-opacity-50 px-1 py-0.5 font-medium">
                            optional
                        </span>
                    )}
                </div>
            )}
            <button
                type="button"
                onClick={toggleDropdown}
                className="my-2 flex w-full cursor-default appearance-none items-center justify-between rounded-xl border-2 bg-white px-4 py-3 text-left text-lg leading-tight text-gray-700 shadow focus:border-transparent focus:outline-none focus:ring-2 focus:ring-black md:px-6 md:py-4 md:text-xl"
            >
                <span className="block truncate">
                    {options.find((option) => option.value === value)?.label ||
                        'Select an option'}
                </span>
                <span className="pointer-events-none">
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </span>
            </button>
            {isOpen && (
                <div className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className="relative cursor-default select-none py-2 pl-10 pr-4 hover:bg-gray-100"
                            onClick={() => toggleOption(option.value)}
                        >
                            {isSelected(option.value) && (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </span>
                            )}
                            <span
                                className={`block truncate text-lg ${isSelected(option.value) ? 'font-medium' : 'font-normal'}`}
                            >
                                {option.label}
                            </span>
                        </div>
                    ))}
                </div>
            )}
            {error && <p className="error-text mt-1 text-sm">{errorText}</p>}
        </div>
    );
};
