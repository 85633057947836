import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import SloganPiggyMascot from '../../../assets/mascots/SloganPiggyMascot.gif'; 
import React, { useState } from 'react';
import GamifiedModal from '../../../components/DialogModal/GamifiedModal';
import FeatureRequestForm from '../../../components/Forms/FeatureRequestForm';
import Button from '../../../components/ui/Button/Button';

/**
 * Renders the FeatureRequestModal component.
 * @returns {React.JSX.Element} The rendered FeatureRequestModal component.
 */
const FeatureRequestModal = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    return (
        <>
           <div className="mt-10 flex flex-col-reverse md:flex-row items-center rounded-2xl bg-gradient-to-l from-fuchsia-700 to-fuchsia-500 px-6 py-6 shadow-lg">
                <div className="flex-1 text-center md:text-left md:mb-0">
                    <h2 className="text-white medium-title">Your Drops, Your Rewards!</h2>
                    <p className="paragraph text-white">
                        Explore all the options below. Can&apos;t find what you&apos;re craving?
                    </p>
                    <Button
                        variant='secondary'
                        className="mt-4 bg-white text-purple-700 font-semibold py-2 px-4 rounded-xl hover:bg-gray-100 border-gray-300 text-gray-700 shadow-[0_3px_0_0_#d1d5db] hover:shadow-[0_6px_0_0_#d1d5db]"
                        onClick={() => setModalIsOpen(true)}
                    >
                        Request a Reward Item
                    </Button>
                </div>
                <div className="flex justify-center md:justify-end">
                    <img
                        src={SloganPiggyMascot}
                        className="m-4 w-28 md:w-40"
                        alt="Your Health is Your Wealth"
                    />
                </div>
            </div>

            <GamifiedModal
                isOpen={modalIsOpen}
                setIsOpen={setModalIsOpen}
                title={'Feature Request'}
            >
                <FeatureRequestForm setIsOpen={setModalIsOpen} type="Reward" />
            </GamifiedModal>
        </>
    );
};

export default FeatureRequestModal;
