import React from 'react';
import FeatureRequestModal from './components/FeatureRequestModal';
import RewardsCategories from './components/RewardsCategories';

/**
 * Renders the Shop page.
 * @returns {React.JSX.Element} The rendered Shop component.
 */
export default function Shop() {
    
    return (
        <div className="h-full max-w-xl">
            <div className="flex items-center border-b-2 pb-4">
                <h1 className="flex-1 text-center large-title">
                    Shop
                </h1>
            </div>
            <FeatureRequestModal />

            <div className="mt-10 space-y-4">
                <h2 className="text-xl font-extrabold">BlueFairy Merch</h2>
                <div className="mt-10 flex flex-wrap gap-4">
                    <RewardsCategories category="Merch" />
                </div>
            </div>

            <div className="mt-10 space-y-4">
                <h2 className="text-xl font-extrabold">Customized Recipes</h2>
                <div className="mt-10 flex flex-wrap gap-4">
                    <RewardsCategories category="Recipes" />
                </div>
            </div>

            <div className="mt-10 space-y-4">
                <h2 className="text-xl font-extrabold">Discounts and Vouchers</h2>
                <div className="mt-10 flex flex-wrap gap-4">
                    <RewardsCategories category="Discounts and Vouchers" />
                </div>
            </div>

            <div className="mt-10 space-y-4">
                <h2 className="text-xl font-extrabold">Consultations and Coaching</h2>
                <div className="mt-10 flex flex-wrap gap-4">
                    <RewardsCategories category="Consultations/Coaching" />
                </div>
            </div>


        </div>
    );
}
