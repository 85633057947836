import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Button from '../../ui/Button/Button';


/**
 * A modal component for displaying notifications with a mascot, title, message, and source.
 * @param {object} props - The component's properties.
 * @param {boolean} props.isOpen - Controls whether the modal is open or closed.
 * @param {Function} props.setIsOpen - A function to update the `isOpen` state.
 * @param {string} props.title - The title of the modal.
 * @param {string} props.source - The source of the fact displayed in the modal.
 * @param {string} props.mascot - The path to the mascot image to display.
 * @param {string} props.message - The main message to display in the modal.
 * @returns {React.JSX.Element} The rendered NotifyModal component.
 */
export default function NotifyModal({
    isOpen,
    setIsOpen,
    title,
    source,
    mascot,
    message,
}) {
    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-50 font-sans"
                        onClose={() => {closeModal()}}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl border-4 border-sapphireBlue-800 bg-gradient-to-b from-sapphireBlue-100 via-white to-sapphireBlue-100 bg-center p-6 text-center align-middle shadow-inner shadow-md text-white transition-all">
                                        <div className="relative flex flex-col items-center justify-center gap-2">
                                            <Dialog.Title
                                                as="h3"
                                                className="pb-2 medium-title text-gray-700 md:large-title"
                                            >
                                                {title}
                                            </Dialog.Title>

                                            <div className="my-4">
                                                <Transition.Child
                                                    enter="animate-expand-bounce duration-1000"
                                                    enterFrom="opacity-0 scale-35"
                                                    enterTo="opacity-100 scale-100"
                                                    className="relative inline-flex w-full items-center justify-center"
                                                >
                                                    <div className="flex flex items-center justify-center rounded-xl"> {/* Center content */}
                                                        <div className="ml-4 flex-col items-center justify-end transition-all"> {/* Main container with width adjustment */}
                                                            <div className="w-full text-gray-700 rounded-xl bg-white p-4 shadow-md text-center text-xl font-bold transition-all duration-200 md:block">
                                                                {message}
                                                                <div className="text-xs mt-4">{source}</div>
                                                            </div>
                                                            <div className="w-full flex h-6 overflow-hidden justify-center">
                                                                <div className="h-4 w-4 rotate-45 -mt-4 origin-top-left transform rounded-sm bg-white shadow-xl drop-shadow"></div>
                                                            </div>
                                                            <div className='w-full flex justify-center'>
                                                                <img src={mascot} alt="Mascot" className='h-32 mt-4' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Transition.Child>
                                            </div>
                                        </div>

                                        <Transition.Child
                                            enter="transition-all ease-in-out duration-700 delay-[500ms]"
                                            enterFrom="opacity-0 translate-y-6"
                                            enterTo="opacity-100 translate-y-0"
                                            leave="transition-all ease-in-out duration-300"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <div className="mt-6 flex w-full">
                                                <Button
                                                    onClick={() => {closeModal()}}
                                                    className="w-full"
                                                >
                                                    Got it!
                                                </Button>
                                            </div>
                                        </Transition.Child>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
    );
}
