import LogoWhite from '../../assets/AI4AI_logo_white.webp';

export function LogoOnly() {
    return (
        <>
            <img
                src={LogoWhite}
                className="h-10 rounded-full bg-sapphireBlue-800 p-1"
                alt="BlueFairy Logo"
            />
        </>
    );
}

export function LogoFull() {
    return (
        <>
            <LogoOnly />
            <span className="medium-title text-left hidden self-center whitespace-nowrap md:block ">
                BlueFairy
                <p className="text-xs font-semibold -mt-0.5 invisible md:visible">Your Health is Your Wealth</p>
            </span>
        </>
    );
}
