import cn from "../utils/cn.jsx";

export const GamifiedStandardTextArea = ({
    id,
    label,
    required,
    placeholder,
    rows,
    error,
    errorText,
    className,
    onChange, // Provided by Controller
    onBlur, // Provided by Controller
    value, // Provided by Controller
    reference, // Provided by Controller, used for registering the textarea
}) => {
    return (
        <>
            {label && (
                <div className="flex items-start gap-2">
                    <label
                        htmlFor={id}
                        className="small-title"
                    >
                        {label}
                    </label>
                    {required ? (
                        <span className="rounded-full bg-red-100 bg-opacity-50 px-1 py-0.5 ml-2 font-medium error-text">
                            *required
                        </span>
                    ) : (
                        <span className="rounded-full bg-green-100 bg-opacity-50 px-1 py-0.5 font-medium success-text">
                            optional
                        </span>
                    )}
                </div>
            )}
            <textarea
                id={id}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={reference}
                className={cn(
                    'w-full appearance-none mb-2 rounded-xl border-2 px-4 py-2 text-lg leading-tight text-gray-700 shadow md:px-6 md:py-3 md:text-xl my-2',
                    error ? 'border-red-500' : '',
                    className
                  )}
                placeholder={placeholder}
                rows={rows || 3}
            />
            {error && (
                <p className="small-title error-text">{errorText}</p>
            )}
        </>
    );
};
