import { TopNav } from './components/Navbar/TopNav.jsx';
import Footer from './components/Footer/Footer.jsx';
import SideNav from './components/Navbar/SideNav.jsx';
import PrivateRoute from './components/PrivateRoute/PrivateRoute.jsx';
import SidePanel from './components/SidePanel/SidePanel.jsx';
import CheckoutPage from './pages/Checkout/Checkout.jsx';
import CheckoutRedirect from './components/CheckoutRedirect/CheckoutRedirect.jsx';
import TopNavLogoOnly from './components/Navbar/TopNavLogoOnly.jsx';
import { Outlet } from 'react-router-dom';
import PrivateAdminRoute from './components/PrivateRoute/PrivateAdminRoute.jsx';
import AdminSideNav from './components/Navbar/AdminSideNav.jsx';
import UserStats from './components/SidePanel/Components/UserStats.jsx';

export const TopNavLayout = () => {
    return (
        <>
            <TopNav />
            <Outlet />
            <Footer />
        </>
    );
};

export const Checkout = () => {
    return (
        <>
            <CheckoutRedirect>
                <TopNavLogoOnly />
                <div className=" mx-auto my-10 min-h-screen w-full max-w-6xl">
                    <div className="mb-10 flex flex-col gap-10 px-4">
                        <div className="min-h-screen">
                            <CheckoutPage />
                        </div>

                        <Footer />
                    </div>
                </div>
            </CheckoutRedirect>
        </>
    );
};

export const SideNavLayout = () => {
    return (
        <PrivateRoute>
            <div className="bg-pictonBlue-200">
                <SideNav />
                <div className="min-h-screen w-full overflow-hidden py-10 sm:ml-20 md:ml-64 ">
                    <div className='md:hidden' id="mobileDropletPoints">
                        <UserStats />
                    </div>
                    <div className="mx-auto flex w-full max-w-6xl flex-col gap-10 overflow-hidden px-4 md:flex-row">
                        <Outlet />
                        <SidePanel />
                    </div>
                </div>
            </div>
        </PrivateRoute>
    );
};

export const LogoOnlyNavLayout = () => {
    return (
        <PrivateRoute>
            <div className="bg-pictonBlue-200">
                <TopNavLogoOnly />
                <div className="mx-auto my-10 min-h-screen w-full max-w-6xl">
                    <div className="mb-10 flex flex-col gap-10 px-4">
                        <div className="min-h-screen">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </PrivateRoute>
    );
};

export const AdminLayout = () => {
    return (
        <PrivateAdminRoute>
            <AdminSideNav />
            <div className="p-4 sm:ml-64">
                <div className="p-4">
                    <Outlet />
                </div>
            </div>
        </PrivateAdminRoute>
    );
};
