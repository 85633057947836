import { Dialog, Transition } from '@headlessui/react';
import { React, useEffect, Fragment, useRef } from 'react';
import Button from '../../../components/ui/Button/Button';
import { useAuth } from '../../../context/AuthContext';

import LakeMascot from '../../../assets/mascots/RippleMascot.gif';
import StoneMascot from '../../../assets/mascots/StoneMascot.gif';

import confetti from 'canvas-confetti';

/**
 * PointsModal component - A modal dialog that displays a celebratory message and animation based on earned points.
 * @param {object} props - The component's properties.
 * @param {boolean} props.isOpen - Controls the visibility of the modal.
 * @param {Function} props.closeModal - A function to close the modal.
 * @param {number} props.pointsDiff - The number of points earned, influencing the message and animation.
 * @returns {React.JSX.Element} - The Points Modal after completing an element or unit.
 */
export default function PointsModal({ isOpen, closeModal, pointsDiff }) {

    const { user } = useAuth();
    const canvasRef = useRef(null);

    useEffect(() => {
        if (isOpen && canvasRef.current) {
            const canvas = canvasRef.current;
            const myConfetti = confetti.create(canvas, { resize: true });
            const duration = 15 * 1000;
            const animationEnd = Date.now() + duration;
            let skew = 1;

            (function frame() {
                const timeLeft = animationEnd - Date.now();
                const ticks = Math.max(200, 500 * (timeLeft / duration));
                skew = Math.max(0.8, skew - 0.001);

                myConfetti({
                    particleCount: 5,
                    startVelocity: 0,
                    ticks: ticks,
                    origin: {
                        x: Math.random(),
                        y: (Math.random() * skew) - 0.2
                    },
                    colors: ['#B0C4DE'],
                    shapes: ['circle'],
                    gravity: 6,
                    scalar: Math.random() * (0.7 - 0.5) + 0.5,
                    drift: Math.random() * (0.4 + 0.4) - 0.4,
                });

                if (timeLeft > 0) {
                    requestAnimationFrame(frame);
                }
            }());
        }
    }, [isOpen, canvasRef.current]);

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-50 font-sans"
                    onClose={closeModal}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25 z-2" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto z-3">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="relative w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all bg-gradient-to-b from-sapphireBlue-100 via-white to-sapphireBlue-100 bg-center border-4 border-sapphireBlue-800">
                                    
                                    <Dialog.Title
                                        as="h3"
                                        className="text-xl md:text-2xl font-bold leading-6 text-gray-900"
                                    >
                                        {user?.name.given[0]}, you just completed{' '}
                                        {pointsDiff === 30
                                            ? 'a Unit'
                                            : 'an Element'}
                                        !
                                    </Dialog.Title>
                                    <div className="mt-4">
                                        {pointsDiff === 30 ? (
                                            <div className="relative">
                                                <canvas 
                                                ref={canvasRef} 
                                                className="absolute top-0 left-0 w-full h-full pointer-events-none"
                                                />
                                                <img 
                                                src={LakeMascot} 
                                                alt="Mascot" 
                                                className="mx-auto rounded-lg blur-[0.2px] py-2 my-8 h-48 relative z-10"
                                                />
                                            </div>
                                            ) : (
                                            <img 
                                                src={StoneMascot} 
                                                alt="Mascot wearing away at a stone" 
                                                className="mx-auto rounded-lg blur-[0.2px] my-8 h-48"
                                            />
                                        )}
                                        <p className="mt-4 px-4 text-sm md:text-base text-gray-700">
                                            You received {pointsDiff} points and{' '}
                                            {pointsDiff === 30
                                                ? <>turning my knowledge lake into an ocean! Let&apos;s keep making a splash!</>
                                                : <>chipping away like a pro! Constant dripping wears away the stone!</>
                                            }
                                            <br/>
                                        </p>
                                    </div>

                                    <div className="mt-6">
                                        <Button
                                            type="secondary"
                                            className="inline-flex justify-center px-4 py-2 text-sm md:text-base font-bold focus:ring-none"
                                            onClick={closeModal}
                                        >
                                            Got it, thanks!
                                        </Button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
