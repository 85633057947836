import React, { useState } from 'react';
import Joyride from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import useIsMobile from '../../shared/hooks/useIsMobile';
import JoyrideSteps from './Components/JoyrideSteps';
import Tooltip from './Components/JoyrideTooltip';
import { useAuth } from '../../context/AuthContext';
import { completeOnboardingAPI } from '../../api/userAPI';
import { useToast } from '../../context/ToastContext';

/**
 * The onboarding tour wrapper, guiding the user through the application.
 * @returns {React.JSX.Element} The rendered Joyride onboarding component.
 */
export default function JoyrideComponent() {
    const [stepIndex, setStepIndex] = useState(0);
    const navigate = useNavigate();
    const { user } = useAuth();
    const { addToast } = useToast();
    const [run, setRun] = useState(!user?.onboarded);
    const isMobile = useIsMobile();
    const [joyrideKey, setJoyrideKey] = useState(Date.now());

    const handleJoyrideCallback = async (data) => {
        const { action, index, type, status } = data;
        const isPreviousAction = action === 'prev';
        const routes = [
            '/journey',
            '/journey',
            '/journey',
            '/health',
            '/quest',
            '/shop',
            '/profile',
            '/journey',
        ];

        if (type === 'step:after') {
            const nextRoute = routes[index + 1];
            const previousRoute = routes[index - 1];

            setStepIndex(isPreviousAction ? index - 1 : index + 1);

            if (isPreviousAction && previousRoute) {
                navigate(previousRoute);
            } else if (nextRoute) {
                navigate(nextRoute);
            }
        }

        if (type === 'tour:end' && action === 'skip') {
            resetTour();
            setStepIndex(routes.length - 1);
            navigate('/journey');
        }

        if (status === 'finished') {
            resetTour();
            setStepIndex(routes.length - 1);
            navigate('/journey');
            try {
                await completeOnboardingAPI();
                setRun(false);
            } catch (error) {
                addToast({
                    title: 'Error',
                    description: 'Failed to complete onboarding',
                    variant: 'error',
                });
            }
        }
    };

    const resetTour = () => {
        setStepIndex(0); // Reset the index to the first step
        setJoyrideKey(Date.now()); // Change the Joyride key to force re-render
        navigate('/journey');
    };

    return (
        <Joyride
            steps={JoyrideSteps(isMobile)}
            run={run}
            continuous={true}
            disableScrolling={true}
            key={joyrideKey}
            stepIndex={stepIndex}
            callback={handleJoyrideCallback}
            hideCloseButton={false}
            hideBackButton={true}
            showSkipButton={true}
            tooltipComponent={(props) => (
                <Tooltip {...props} resetTour={resetTour} />
            )}
            styles={{
                options: {
                    arrowColor: '#ffffff',
                    backgroundColor: '#ffffff',
                    overlayColor: 'rgba(0, 0, 0, 0.2)',
                    primaryColor: '#303ECE',
                    textColor: '#4B4B4B',
                    zIndex: 1000,
                    borderRadius: 100,
                },
                spotlight: {
                    borderRadius: 18,
                    padding: 4,
                },
                tooltip: {
                    borderRadius: 16,
                    borderColor: '#e5e7eb',
                    borderWidth: '2px',
                },
            }}
        />
    );
}
