export const GamifiedCheckbox = ({
    label,
    required,
    id,
    value,
    onChange,
    className,
    error,
    errorText,
}) => {
    return (
        <div className={`flex flex-col ${className}`}>
            {label && (
                <div className=" flex items-start gap-2">
                    <label
                        htmlFor={id}
                        className="small-title mb-1"
                    >
                        {label}
                    </label>
                    {required ? (
                        <span className="error-text rounded-full bg-red-100 bg-opacity-50 px-1 py-0.5 font-medium">
                            *required
                        </span>
                    ) : (
                        <span className=" success-text rounded-full bg-green-100 bg-opacity-50 px-1 py-0.5 font-medium">
                            optional
                        </span>
                    )}
                </div>
            )}
            <div className="py-3 my-2 md:py-4">
                <input
                    type="checkbox"
                    name={id}
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    className="h-5 w-5 text-blue-600 transition-colors duration-200 ease-in-out"
                />
            </div>
            {error && (
                <p className="small-title error-text mt-1">{errorText}</p>
            )}
        </div>
    );
};
