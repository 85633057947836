import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import Button from '../../../components/ui/Button/Button';

/**
 * The OTP input component with validation.
 * @param {object} props The props for the component.
 * @param {number} props.numberOfChars The number of digits in the OTP.
 * @param {string} props.email The email address.
 * @returns {React.JSX.Element} The OTP input component.
 */
export default function OtpInputWithValidation({ numberOfChars, email }) {
    const navigate = useNavigate();
    const { verifyOTP } = useAuth();
    const [otp, setOtp] = useState(new Array(numberOfChars).fill(''));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [otpError, setOtpError] = useState(null);
    const otpBoxReference = useRef([]);

    useEffect(() => {
        otpBoxReference.current[0].focus();
    }, []);

    const handleChange = async (value, index) => {
        if (value.length > 1) {
            // Handle paste operation
            const pastedData = value.slice(0, numberOfChars).split('');
            const newOtp = [...otp];
            pastedData.forEach((char, i) => {
                if (index + i < numberOfChars) {
                    newOtp[index + i] = char;
                }
            });
            setOtp(newOtp);
            const lastFilledIndex =
                Math.min(index + pastedData.length, numberOfChars) - 1;
            otpBoxReference.current[lastFilledIndex].focus();
            if (lastFilledIndex === numberOfChars - 1) {
                await handleSubmit(newOtp);
            }
        } else {
            // Handle single character input
            let newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (value && index < numberOfChars - 1) {
                otpBoxReference.current[index + 1].focus();
            }
            if (value && index === numberOfChars - 1) {
                await handleSubmit(newOtp);
            }
        }
    };

    const handleBackspaceAndEnter = async (e, index) => {
        if (e.key === 'Backspace' && !e.target.value && index > 0) {
            otpBoxReference.current[index - 1].focus();
        } else if (e.key === 'ArrowLeft' && index > 0) {
            otpBoxReference.current[index - 1]?.focus();
        } else if (e.key === 'ArrowRight' && index < numberOfChars - 1) {
            otpBoxReference.current[index + 1]?.focus();
        } else if (e.key === 'Enter' && index === numberOfChars - 1) {
            await handleSubmit(otp);
        }
    };

    const handlePaste = async (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData
            .getData('text')
            .slice(0, numberOfChars)
            .split('');
        await handleChange(pastedData.join(''), 0);
    };

    const handleSubmit = async (otpVal) => {
        setIsSubmitting(true);
        if (!otpVal) {
            setOtpError('Please enter the OTP.');
        }
        try {
            const otpValue = otpVal.join('');
            const body = { email, otp: otpValue };
            await verifyOTP(body);
            navigate('/checkout');
        } catch (error) {
            setOtpError('Invalid OTP. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const clearAll = () => {
        setOtp(new Array(numberOfChars).fill(''));
        setOtpError('');
        otpBoxReference.current[0]?.focus();
    };

    return (
        <div className="mt-10">
            <div className="flex items-center justify-center gap-4">
                {otp.map((digit, index) => (
                    <input
                        key={index}
                        type="text"
                        inputMode="text"
                        maxLength={numberOfChars - index}
                        value={digit}
                        onChange={(e) => handleChange(e.target.value, index)}
                        onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                        onPaste={handlePaste}
                        ref={(el) => (otpBoxReference.current[index] = el)}
                        disabled={isSubmitting}
                        aria-label={`Character ${index + 1}`}
                        className={`block h-auto w-12 appearance-none rounded-md border border-sapphireBlue-600 p-2 focus:border-2 focus:outline-none md:w-20 md:p-3 md:text-2xl`}
                    />
                ))}
            </div>
            {otpError && (
                <p className="small-title error-text mt-4">{otpError}</p>
            )}
            <div className="mt-14 flex justify-around">
                <Button
                    variant="secondary"
                    onClick={clearAll}
                    disabled={isSubmitting}
                >
                    Clear
                </Button>

                <Button
                    variant="primary"
                    onClick={async () => await handleSubmit(otp)}
                    isLoading={isSubmitting}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
}
