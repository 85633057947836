import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ProgressBar from '../../components/ProgressStepper/ProgressBar';
import { getRandomMessage } from './utils/getRandomMessage';
import { XMarkIcon } from '@heroicons/react/20/solid';
import GamifiedField from './components/GamifiedField';
import { Transition } from '@headlessui/react';
import { FormEndModal } from './components/FormEndModal';
import Button from '../../components/ui/Button/Button';
import { DevTool } from '@hookform/devtools';
import cn from '../ui/utils/cn.jsx';
import ExcitedMascot from '../../assets/mascots/DefaultMascotWithShadow.webp';
import ErrorMascot from '../../assets/mascots/ErrorMascot.webp';
import YogaMascot from '../../assets/mascots/YogaMascot.gif';
import CouchMascot from '../../assets/mascots/CouchMascot.gif';
import BeanieMascot from '../../assets/mascots/ThinkingBeanieMascot.gif';
import { useAuth } from '../../context/AuthContext';

const GamifiedDynamicForm = ({
    config,
    formSchema,
    formWelcomeMessage,
    totalSteps,
    onFormSubmission,
    defaultValues = undefined,
}) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [formError, setFormError] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { user } = useAuth();

    const mascots = [YogaMascot, CouchMascot, BeanieMascot];
    const [randomMascot, setRandomMascot] = useState(mascots[0]);

    const formMethods = useForm({
        defaultValues: defaultValues,
    });

    useEffect(() => {
        if (currentStep === 1 && !feedback) {
            // Set initial feedback when the form is first displayed
            setFeedback(
                `Hey ${user?.name.given[0]}! ${formWelcomeMessage ? formWelcomeMessage : "Great to see you sprouting up here again. Let's dig in!"}`
            );
            setShowFeedback(true);
        }

        let timeoutId;
        if (feedback && currentStep !== 1) {
            // Only reset feedback after the initial greeting
            timeoutId = setTimeout(() => {
                setShowFeedback(false); // Ensures feedback is set to null after timeout
            }, 5000); // Matches transition duration
        }

        return () => clearTimeout(timeoutId);
    }, [feedback, currentStep]);

    const validateCurrentStepFields = async (
        field,
        data,
        schema,
        basePath = ''
    ) => {
        let isValid = true; // Assume validation will pass initially.

        const validateField = async (itemData, fieldPath) => {
            try {
                await schema.validateAt(fieldPath, formMethods.getValues());
            } catch (error) {
                if (
                    error.name === 'TypeError' &&
                    error.message.includes('branch is not a function')
                ) {
                    console.error(
                        'Schema resolution error. Check the schema definition for:',
                        fieldPath
                    );
                } else if (error.name === 'ValidationError') {
                    formMethods.setError(fieldPath, {
                        type: 'manual',
                        message: error.errors[0], // Use the first error message for simplicity
                    });
                } else {
                    console.error('Unexpected error during validation:', error);
                }
                isValid = false; // Mark validation as failed.
            }
        };

        if (field.type === 'dynamic') {
            // For dynamic fields, validate each item in the array.
            if (field.required && data[field.name].length === 0) {
                formMethods.setError(`${basePath}${field.name}`, {
                    type: 'manual',
                    message: `At least one ${field.label} is required`,
                });
                isValid = false;
            }

            for (let index = 0; index < data[field.name].length; index++) {
                const item = data[field.name][index]; // Current item in the array for dynamic fields.
                let fieldName = `${basePath}${field.name}[${index}]`;
                validateField(item, fieldName);

                for (let subField of field.subFields) {
                    fieldName = `${basePath}${field.name}[${index}].${subField.name}`;
                    const itemData = item[subField.name];

                    if (
                        subField.type === 'group' ||
                        subField.type === 'dynamic'
                    ) {
                        fieldName = `${basePath}${field.name}[${index}]`;
                        // Recursively handle nested 'group' or 'dynamic' fields.
                        let returnedValue = await validateCurrentStepFields(
                            subField,
                            { [subField.name]: itemData },
                            schema,
                            `${fieldName}.`
                        );
                        if (!returnedValue) {
                            isValid = false;
                        }
                    } else {
                        // Direct validation for standard field types.
                        await validateField(itemData, fieldName);
                    }
                }
            }
        } else if (field.type === 'group') {
            // For group fields, individually validate each subfield.
            for (let subField of field.subFields) {
                const fieldName = `${basePath}${field.name}.${subField.name}`;
                const itemData = data[field.name][subField.name];
                if (subField.type === 'group' || subField.type === 'dynamic') {
                    // Recursively handle nested 'group' or 'dynamic' fields within a group.
                    let returnedValue = await validateCurrentStepFields(
                        subField,
                        { [subField.name]: itemData },
                        schema,
                        `${fieldName}.`
                    );
                    if (!returnedValue) {
                        isValid = false;
                    }
                } else {
                    // Direct validation for standard subfield types within a group.
                    await validateField(itemData, fieldName);
                }
            }
        } else {
            // Direct validation for standard (non-dynamic, non-group) fields.
            const fieldName = `${basePath}${field.name}`;
            const itemData = data[field.name];
            await validateField(itemData, fieldName);
        }

        return isValid;
    };

    const changeMascot = () => {
        const chosenMascot = mascots[Math.floor(Math.random() * mascots.length)];
        setRandomMascot(chosenMascot);
    }

    const onNext = async () => {
        const formData = formMethods.getValues(); // Get current form data
        console.log(formData);
        const field = config?.fields[currentStep - 1]; // Get the current field configuration
        const isValid = await validateCurrentStepFields(
            field,
            formData,
            formSchema,
            ''
        );
        // Check for errors in the current step
        if (isValid) {
            if (currentStep < totalSteps) {
                setCurrentStep(currentStep + 1);
                setFormError(!isValid);
                setFeedback(getRandomMessage('positiveReinforcement'));
                changeMascot();
            } else {
                // Handle form submission for the last step
                try {
                    setFormError(!isValid);
                    await onFormSubmission(formData);
                } catch (error) {
                    setShowFeedback(true);
                    setFeedback(
                        'Submission error: Something went wrong. Please try again.'
                    );
                }
            }
        } else {
            setFormError(!isValid);
            setFeedback(getRandomMessage('corrections'));
        }
    };

    const onPrevious = () => {
        setCurrentStep(currentStep - 1);
        changeMascot();
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <FormProvider {...formMethods}>
            <div className="flex h-full flex-col">
                <div className="flex flex-1 flex-col">
                    <div>
                        <h1 className="large-title">{config?.title}</h1>
                        <h2 className="small-title tracking-wide text-gray-500">
                            {config?.description}
                        </h2>
                    </div>

                    <div className="mt-6 flex items-center gap-1 px-4 md:gap-10">
                        <button
                            className="font-bold text-gray-400"
                            type="button"
                            onClick={() => setModalIsOpen(true)}
                        >
                            <XMarkIcon className="h-8 stroke-gray-400 " />
                        </button>
                        <ProgressBar
                            currentStep={currentStep}
                            totalSteps={totalSteps}
                        />
                    </div>

                    <form
                        onSubmit={formMethods.handleSubmit(onNext)}
                        className="min-w-2xl flex h-full w-full flex-1 flex-col items-center justify-center"
                    >
                        
                        <div className="mb-40 mt-10 flex-col h-full w-full max-w-2xl items-center justify-center ">

                        <div className='w-full flex items-center justify-center mb-4'>
                            {(!config.fields[currentStep - 1]?.subFields || config.fields[currentStep - 1]?.subFields?.length <= 2) && (
                                <img src={randomMascot} alt="Your image" className="h-48 pb-6" />
                            )}
                        </div>

                            {config?.fields.map(
                                (field, index) =>
                                    index === currentStep - 1 && (
                                        <div className=" w-full" key={index}>
                                            <GamifiedField field={field} />
                                        </div>
                                    )
                            )}
                        </div>

                        <div className={`fixed bottom-0 z-10 w-full `}>
                            {feedback && (
                                <Transition
                                    appear={true}
                                    show={showFeedback}
                                    enter="transition ease-linear duration-300 transform"
                                    enterFrom="translate-y-10"
                                    enterTo="translate-y-0"
                                    leave="transition ease-linear duration-300 transform"
                                    leaveFrom="translate-y-0"
                                    leaveTo="translate-y-10"
                                    afterLeave={() => setFeedback(null)}
                                >
                                    {/* Place this behind the buttons div using negative margin and z-index */}
                                    <div
                                        className={`absolute inset-x-0 bottom-0 z-0 md:hidden`}
                                    >
                                        <p
                                            className={cn(
                                                'rounded-t-xl border-t-2 bg-white px-2 pt-3 text-center text-xl font-bold',
                                                feedback && 'bg-green-200',
                                                formError &&
                                                    'error-text bg-red-200'
                                            )}
                                        >
                                            <p className="rounded-xl bg-white p-2 text-center text-xl font-bold transition-all duration-200 md:block">
                                                {feedback}
                                            </p>
                                            <div className="flex h-6 w-full justify-center overflow-hidden">
                                                <div className="-mt-4 h-4 w-4 origin-top-left rotate-45 transform rounded-sm bg-white shadow-xl drop-shadow"></div>
                                            </div>
                                        </p>
                                    </div>
                                </Transition>
                            )}

                            {/* This div should be visually above the feedback, hence a higher z-index */}
                            <div
                                className={cn(
                                    'relative w-full border-2 bg-white px-4 py-2 shadow-md md:py-4',
                                    feedback && 'bg-green-200',
                                    formError && 'error-text bg-red-200'
                                )}
                            >
                                <div className="mx-auto flex max-w-6xl items-center justify-between">
                                    <Button
                                        type="button"
                                        onClick={onPrevious}
                                        disabled={currentStep < 2}
                                        variant="primary"
                                    >
                                        Prev
                                    </Button>
                                    <div className="flex items-center md:space-x-4">
                                        <div className="relative hidden md:block">
                                            <div className="container mx-auto max-w-2xl">
                                                <div
                                                    className={cn(
                                                        'ml-4 flex items-center justify-end transition-all',
                                                        feedback
                                                            ? 'animate-expand-bounce'
                                                            : 'w-3/4'
                                                    )}
                                                >
                                                    <div
                                                        className={cn(
                                                            'w-full rounded-xl bg-white p-4 text-center text-xl font-bold transition-all duration-200 md:block',
                                                            formError &&
                                                                'error-text'
                                                        )}
                                                    >
                                                        {feedback}
                                                    </div>
                                                    {feedback && (
                                                        <div className="w-3 overflow-hidden">
                                                            <div className="h-4 origin-top-left rotate-45 transform rounded-sm bg-white shadow-xl drop-shadow"></div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {feedback ? (
                                            <img
                                                src={
                                                    formError
                                                        ? ErrorMascot
                                                        : ExcitedMascot
                                                }
                                                alt="Mascot"
                                                className="mr-4 h-16 animate-expand-bounce transition-all md:h-24"
                                            />
                                        ) : (
                                            <div className="h-16 md:h-24" />
                                        )}
                                    </div>
                                    <Button
                                        variant={
                                            formError
                                                ? 'destructive'
                                                : 'primary'
                                        }
                                        type="submit"
                                    >
                                        {currentStep < totalSteps
                                            ? 'Next'
                                            : 'Submit'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <FormEndModal isOpen={modalIsOpen} closeModal={closeModal} />
            </div>
            <DevTool control={formMethods.control} />
        </FormProvider>
    );
};

export default GamifiedDynamicForm;
