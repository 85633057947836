import React from 'react';
import { useForm } from 'react-hook-form';
import GamifiedModal from '../../../components/DialogModal/GamifiedModal';
import Button from '../../../components/ui/Button/Button';
import { useToast } from '../../../context/ToastContext';
import { sendQuestInterestFormAPI } from '../../../api/questAPI';

/**
 * Renders the Interest Form component for Quests.
 * @param {object} props The component props.
 * @param {boolean} props.isOpen The state of the modal.
 * @param {Function} props.setIsOpen The function to set the modal state.
 * @param {object} props.user The user object.
 * @param {object} props.basicInformation The basic information object.
 * @param {string} props.selectedQuest The selected quest.
 * @returns {React.JSX.Element} The rendered InterestForm component.
 */
export default function InterestForm({
    isOpen,
    setIsOpen,
    user,
    basicInformation,
    selectedQuest,
}) {
    const { addToast } = useToast();
    const { register, handleSubmit } = useForm();

    const onSubmit = async (data) => {
        if (!basicInformation) {
            addToast({
                variant: 'error',
                title: 'Missing Element 1 of Unit 1',
                description:
                    'Element 1 of Unit 1 must be completed in order to be eligible to opt-in for this quest',
            });
            return;
        }
        const formData = { quest: selectedQuest, ...data };
        try {
            await sendQuestInterestFormAPI(formData);
            addToast({
                variant: 'success',
                title: 'Interest Form Submitted',
                description: `Interest and opt-in request for ${selectedQuest} submitted successfully`,
            });
        } catch (error) {
            addToast({
                variant: 'error',
                title: 'Error',
                description: 'Unable to submit Interest Form. Try again.',
            });
        }
        setIsOpen(false);
    };

    return (
        <GamifiedModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title="Interested in this quest?"
            description="Request access by verifying and submitting your information below. We'll let you know once you're off the list."
        >
            {basicInformation ? (
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
                    <div className="space-y-2 px-4">
                        <p className="font-bold">
                            First Name: {user.name.given[0]}
                        </p>
                        <p className="font-bold">
                            Last Name: {user.name.family}
                        </p>
                        <p className="font-bold">Email: {user.email}</p>
                        <p className="font-bold">
                            Phone Number: {user.phoneNumber.value}
                        </p>
                        <p className="font-bold">
                            Mailing Address: {basicInformation.line1}{' '}
                            {basicInformation.line2} {basicInformation.city},{' '}
                            {basicInformation.state} {basicInformation.zipCode}{' '}
                        </p>
                    </div>

                    <textarea
                        name="comments"
                        placeholder="Add any comments here"
                        {...register('comments')}
                        className="w-full appearance-none rounded-xl border-2 px-4 py-2 leading-tight text-gray-700 shadow md:px-6 md:py-3"
                    />
                    <p className="paragraph text-center">
                        If the address above isn&apos;t correct, please update
                        &quot;Basic Information Element&quot; before opting in.
                    </p>

                    <div className="w-full text-center">
                        <Button type="submit" disabled={!basicInformation}>
                            Submit
                        </Button>
                    </div>
                </form>
            ) : (
                <p className="text-bold error-text">
                    Element 1 of Unit 1 must be completed in order to be
                    eligible to opt-in for this quest
                </p>
            )}
        </GamifiedModal>
    );
}
