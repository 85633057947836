import ButtonSpinner from '../../LoadSpinner/ButtonSpinner';
import cn from '../utils/cn';
import React from 'react';

/**
 * Button component.
 * @param {object} props - The component props.
 * @param {React.ReactNode} props.children - The content of the button.
 * @param {string} props.variant - The variant of the button. Can be 'primary', 'secondary', 'destructive', 'outline', 'ghost', 'link', 'icon', 'textWithIcon', 'loading', 'asChild'.
 * @param {string} props.className - The additional class name for the button.
 * @param {boolean} props.isLoading - Indicates if the button is in loading state.
 * @param {React.ReactNode} props.icon - The icon element for the button.
 * @param {object} props.props - Additional props for the button element.
 * @returns {React.JSX.Element} The rendered button component.
 */
export default function Button({
    children,
    variant = 'primary',
    className = '',
    isLoading = false,
    icon,
    ...props
}) {
    const baseClasses =
        'border-2 py-2 px-4 md:text-lg font-extrabold rounded-xl transition ease-in-out duration-50 disabled:bg-gray-200 disabled:border-gray-300 disabled:text-gray-400 transition-all duration-100 ease-in-out active:shadow-sm disabled:shadow-none';

    const variantClasses = {
        primary: cn(
            'bg-sapphireBlue-800 border-sapphireBlue-800 text-white shadow-[0_5px_0_0_#1e3a8a] hover:bg-sapphireBlue-600 hover:shadow-[0_9px_0_0_#1e3a8a]',
            !props.disabled && 'hover:-translate-y-1 active:translate-y-1'
        ),
        secondary: cn(
            'bg-pictonBlue-200 border-pictonBlue-200 shadow-[0_5px_0_0_#40a9f1] transition-all hover:bg-pictonBlue-400 hover:shadow-[0_9px_0_0_#40a9f1]',
            !props.disabled && 'hover:-translate-y-1 active:translate-y-1'
        ),
        destructive: cn(
            'bg-red-600 border-red-600 text-white shadow-[0_5px_0_0_#c53030] hover:bg-red-400 hover:shadow-[0_9px_0_0_#c53030]',
            !props.disabled && 'hover:-translate-y-1 active:translate-y-1'
        ),
        outline: 'hover:bg-pictonBlue-200',
        ghost: 'bg-transparent text-gray-700 border-none p-0',
        link: 'bg-transparent text-blue-500 underline border-none p-0',
        icon: 'p-2 bg-transparent border-none',
        textWithIcon: 'flex items-center space-x-2',
        loading: 'flex items-center space-x-2 bg-gray-300 text-gray-500 cursor-not-allowed',
    };        
    
    return (
            <button
                className={cn(baseClasses, variantClasses[variant], className)}
                {...props}
                disabled={isLoading || props.disabled}
            >
                {isLoading ? (
                    <ButtonSpinner />
                ) : variant === 'icon' ? (
                    icon
                ) : variant === 'textWithIcon' ? (
                    <>
                        {icon}
                        {children}
                    </>
                ) : (
                    children
                )}
            </button>
    );
}
