import React from 'react';
import Button from '../../ui/Button/Button';
import cn from '../../ui/utils/cn';

/**
 * Renders a custom tooltip for the Joyride tour.
 * @param {object} props - The component props.
 * @param {boolean} props.isLastStep - Indicates if this is the last step in the tour.
 * @param {object} props.primaryProps - The Props to pass to the primary button (e.g., 'Next' or 'Let's Go!').
 * @param {object} props.skipProps - The Props to pass to the 'Skip' button.
 * @param {object} props.step - The current step data from Joyride.
 * @param {object} props.backProps - The Props to pass to the 'Back' button.
 * @param {object} props.resetTour - The function to restart the tour.
 * @param {object} props.tooltipProps - Additional props to pass to the tooltip container div.
 * @returns {React.JSX.Element} The rendered tooltip component.
 */
export default function Tooltip({
    isLastStep,
    primaryProps,
    skipProps,
    backProps,
    step,
    resetTour,
    tooltipProps,
  }) {
    return (
      <div
        {...tooltipProps}
        className="bg-gradient-to-l from-[#FFFFFF] via-[#F7F9FF] to-[#EFF3FF] overflow-hidden max-w-[400px] min-w-[290px] rounded-lg shadow-md p-2 text-gray-700 font-sans text-center border-2 border-[#EFF3FF]"
      >
        <div className="p-4">
          {step.title && (
            <h3 className="medium-title">
              {step.title}
            </h3>
          )}
          {step.content && <div>{step.content}</div>}
        </div>
        <div className="p-2 w-full">
          <div className="flex justify-between">
            {!isLastStep && (
              <Button
                variant='secondary'
                {...skipProps}
                className="border-2 border-[#d1d5db] bg-white text-gray-700 shadow-lg shadow-[0_5px_0_0_#d1d5db] hover:shadow-[0_9px_0_0_#d1d5db] hover:bg-gray-100"
              >
                Skip
              </Button>
              )
            }
            <div className={cn("flex space-x-2", isLastStep ? "justify-center w-full" : "justify-between")}>
              {!isLastStep ? (
                <Button
                variant='secondary'
                {...backProps}
                className="border-2 border-[#d1d5db] bg-white text-gray-700 shadow-lg shadow-[0_5px_0_0_#d1d5db] hover:shadow-[0_9px_0_0_#d1d5db] hover:bg-gray-100"
              >
                Back
              </Button>
              ) : (
                <Button
                variant="secondary"
                onClick={resetTour}
                className="border-2 border-[#d1d5db] bg-white text-gray-700 shadow-lg shadow-[0_5px_0_0_#d1d5db] hover:shadow-[0_9px_0_0_#d1d5db] hover:bg-gray-100"
                >
                  Restart Tour
                </Button>
              )}
              <Button
                {...primaryProps}
                className=""
              >
                {isLastStep ? "Let's Go!" : "Next"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  